import { LossePlaatjie, LosseLink, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Knowledge, KnowledgeToCategoryConnection, Maybe } from '~/graphql/types'
import type { Edge } from '../OverviewDefault'

export default function KnowledgeItem({ edge }: { edge: Edge<Knowledge> }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="col-span-1 box-shadow flex flex-col items-center">
      <div
        className={clsx(
          'w-full aspect-square flex items-center justify-center bg-hh-salem/20 h-[200px] lg:h-[250px]',
          knowledgeColor(edge?.node?.categories)
        )}
      >
        <LossePlaatjie
          maxwidth={350}
          src={edge?.node?.recap?.image}
          className="block w-[60%] h-[60%] sm:w-[75%] sm:h-[75%] xl:w-[95%] xl:h-[95%] max-h-[200px] object-contain mx-auto knowledge-image"
        />
      </div>
      <div className="p-3 md:p-5 xl:p-7 w-full flex-grow flex flex-col items-center">
        <div className="flex-grow w-full">
          <div className="flex items-center mb-4 overflow-x-auto no-scrollbar h-[26px] -mr-3 md:-mr-5 xl:-mr-7">
            {edge?.node?.categories?.nodes?.map((type, index) => (
              <div key={index} className="tag mr-2">
                {type?.name}
              </div>
            ))}
          </div>
          <h2 className="title text-lg lg:text-xl xl:text-2xl font-light">{edge?.node?.title}</h2>
        </div>

        <div className="mt-5 md:mt-7 xl:mt-12 w-full">
          <div className="text-center">
            <LosseLink to={edge?.node?.uri} className="md:min-w-[175px] min-w-0 button-mine-shaft-opaque md:inline-flex block">
              Read
            </LosseLink>
          </div>
          <div className="text-center mt-3 md:mt-4">
            <button
              type="button"
              onClick={() => {
                searchParams.set('download-knowledge', edge?.node?.databaseId.toString())
                setSearchParams(searchParams, {
                  replace: true,
                  state: {
                    scroll: false
                  }
                })
              }}
              className="md:min-w-[175px] min-w-0 button-blue md:inline-flex block md:w-auto w-full"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export function knowledgeColor(categories: Maybe<KnowledgeToCategoryConnection> | undefined) {
  if (categories?.nodes?.find((item) => item?.databaseId === 252)) {
    return 'bg-hh-blue/20'
  }

  return 'bg-hh-salem/20'
}
