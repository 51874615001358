import type { Page_Flexcontent_Flex_Quote } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function QuoteDefault({ fields }: { fields: Page_Flexcontent_Flex_Quote }) {
  return (
    <section className="bg-hh-blue my-10 lg:my-16">
      <div className="container lg:max-w-screen-lg spacing-top">
        <div className="grid grid-cols-5">
          <div className="col-span-2 relative">
            <LossePlaatjie src={fields.image} className="absolute bottom-0 left-0 w-[300px] object-contain" maxwidth={350} />
          </div>
          <div className="relative col-span-3 section">
            <svg
              width="35"
              height="28"
              viewBox="0 0 35 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute -left-[15px] -top-[15px] text-white opacity-50"
            >
              <path
                d="M20.5172 28H33.9138V13.5172H28.6035V11.8276C28.6035 7.72413 30.0517 6.39655 35 6.39655V0H32.5862C27.2759 0 23.6552 1.44827 21.9655 4.46552C20.8793 6.39655 20.5172 8.81034 20.5172 13.8793V28ZM0 28H13.3966V13.5172H8.08621V11.8276C8.08621 7.60344 9.53448 6.39655 14.6034 6.39655V0H12.069C6.75862 0 3.13793 1.44827 1.44828 4.46552C0.36207 6.39655 0 8.68965 0 13.8793V28Z"
                fill="currentColor"
              />
            </svg>
            <blockquote className="font-hh-heading text-xl md:text-2xl xl:text-3xl leading-5xl font-light text-black">
              {fields?.description}
            </blockquote>
            <div className="mt-5 flex justify-end">
              <div>
                <div className="text-white font-hh-heading text-lg">{fields.name}</div>
                <div className="mt-3 flex justify-end">
                  <LosseLink to={fields.link?.url} target={fields.link?.target as string} className="button-blue text-hh-mine-shaft">
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-3">
                      <path
                        d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
                        fill="currentColor"
                      />
                    </svg>
                    {fields.link?.title}
                  </LosseLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
