import clsx from 'clsx'

export default function Radar({ className = '' }: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div className={clsx(className, 'w-[150px] h-[150px] lg:w-[200px] lg:h-[200px] flex items-center justify-center')}>
      <div className="absolute radar w-full h-full" />
      <div className="h-[90%] w-[90%] relative z-10 bg-hh-mine-shaft rounded-full overflow-hidden flex flex-col items-center uppercase">
        <div className="text-[9px] lg:text-[10px] text-white mt-4 lg:mt-7 w-[50%]">
          <div className="flex items-center">
            <span className="text-hh-blue mr-1">Outside</span>
            35°C
          </div>
          <div className="flex items-center">
            <span className="text-hh-blue mr-1">Humidity</span>
            80%
          </div>
        </div>
        <div className="lg:mt-1 relative w-full text-center flex justify-center">
          <div className="text-white text-4xl lg:text-6xl font-hh-heading font-bold relative -ml-6">
            22
            <div className="absolute -right-7 -top-1 lg:top-[6px] text-m lg:text-3xl">°C</div>
            <div className="absolute -right-9 bottom-1 lg:bottom-1 text-[9px] lg:text-[10px] text-hh-blue mr-1 leading-3">Inside</div>
          </div>
        </div>
        <div className="mt-1 lg:mt-2 w-full text-center text-[9px] lg:text-[10px] text-white leading-3">
          Provided by
          <br />
          Heinen &amp; Hopman
        </div>
      </div>
    </div>
  )
}
