import Content from '~/components/elements/Content'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import clsx from 'clsx'
import Radar from '~/components/elements/Radar'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section>
      <div
        className={clsx(
          fields.video
            ? 'relative overflow-hidden containerize lg:flex flex flex-col justify-end bg-hh-mine-shaft lg:min-h-[750px] lg:h-[calc(100vh-175px)] md:max-h-[720px] lg:max-h-[950px]'
            : 'relative overflow-hidden containerize lg:flex flex items-end bg-hh-silver lg:min-h-[750px] h-[calc(100vh-100px)] lg:h-[calc(100vh-175px)] max-h-[550px] md:max-h-[720px] lg:max-h-[950px]'
        )}
      >
        {fields.video ? (
          <video muted autoPlay loop playsInline className="min-w-full lg:min-h-full w-auto lg:absolute object-cover h-auto top-0">
            <source src={fields.video} type="video/mp4" />
          </video>
        ) : (
          <LossePlaatjie
            maxwidth={2000}
            loading="eager"
            src={fields.imageLanding}
            className="absolute left-0 top-0 z-0 h-full w-full object-cover"
          />
        )}
        <div
          className={clsx(
            'absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black/60 lg:to-black/40',
            fields.video && 'lg:block hidden'
          )}
        />
        <div
          className={clsx('relative z-20 container max-w-4xl mx-auto mt-auto', !fields.video && 'py-16', fields.video && 'py-10 lg:py-16')}
        >
          <Content className="lg:mb-10 content-lg text-white children-h1:text-4xl lg:children-h1:text-6xl children-p:text-lg lg:children-p:text-2xl children-a:block sm:children-a:inline-flex">
            {fields.description}
          </Content>
        </div>
        {fields.includeRadar && <Radar className="absolute top-2 right-2 sm:top-5 sm:right-5 lg:top-12 lg:right-12" />}
      </div>
    </section>
  )
}
