import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import { motion } from 'framer-motion'
import type { Page_Flexcontent_Flex_InfoBlocks } from '~/graphql/types'
import { useState } from 'react'

export default function InfoBlocksDefault({ fields }: { fields: Page_Flexcontent_Flex_InfoBlocks }) {
  const [openIndex, setOpenIndex] = useState(-1)

  return (
    <section>
      <div className="container section max-w-screen-xl">
        <div className="grid md:grid-cols-3 gap-2 md:gap-5 xl:gap-16">
          {fields.blocks?.map((block, index) => (
            <div
              key={index}
              className="col-span-1 h-[400px] md:h-[550px] relative overflow-hidden flex items-end"
              onMouseEnter={() => setOpenIndex(index)}
              onMouseLeave={() => setOpenIndex(-1)}
            >
              <div className="absolute z-0 inset-0">
                <LossePlaatjie maxwidth={600} src={block?.image} className="object-cover w-full h-full" />
              </div>
              <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black" />
              <div className="pt-10 pb-4 md:pb-5 lg:pb-10 px-4 md:px-5 lg:px-10 relative z-20">
                <Content className="content text-white mb-3 md:mb-5">{`<h2>${block?.title}</h2>`}</Content>
                <motion.div
                  animate={openIndex === index ? 'open' : 'closed'}
                  variants={{
                    open: {
                      opacity: 1,
                      height: 'auto'
                    },
                    closed: {
                      opacity: 0,
                      height: 0
                    }
                  }}
                  className="h-[0] opacity-0"
                >
                  <Content className="content text-white">{block?.description}</Content>
                </motion.div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
