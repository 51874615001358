/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from '@ubo/losse-sjedel'
import { useLosseData } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import type { Component_Popup } from '~/graphql/types'
import Content from '../elements/Content'
import Form from '../elements/form/Form'
import Loading from '../elements/Loading'
import Modal from '../elements/Modal'

export default function Popup({ name, componentId }: { name: string; componentId: number }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const open = searchParams.get(name)
  const [isOpen, setOpen] = useState(false)
  const [response, state] = useLosseData<{ data: Component_Popup }>(
    'Popup',
    {
      id: componentId
    },
    !!isOpen
  )

  useEffect(() => {
    if (!open) {
      return
    }

    setOpen(true)
  }, [open])

  useEffect(() => {
    if (!isOpen && open) {
      searchParams.set(name, '')
      setSearchParams(searchParams, {
        replace: true,
        state: {
          scroll: false
        }
      })
    }
  }, [isOpen])

  const { data } = response || {}

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false)
        }}
      >
        {state === 'loading' && (
          <div className="flex justify-center py-20">
            <Loading />
          </div>
        )}
        {state === 'done' && (
          <div className="sm:grid grid-cols-2 gap-10 lg:gap-20 py-5 lg:py-10">
            <div className="col-span-1">
              <Content className="content">{data?.description}</Content>
            </div>
            <div className="col-span-1 mt-14 sm:mt-0">
              <Form data={data?.form} backgroundColor="white" />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
