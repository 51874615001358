import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function TextImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section
      className={clsx(
        'relative min-h-[400px]',
        fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
        fields.backgroundColor === 'mineshaft' && 'bg-hh-mine-shaft',
        fields.backgroundColor === 'white' && 'bg-white',
        fields.backgroundColor === 'pattensblue' && 'bg-hh-blue/20'
      )}
    >
      <div
        className={clsx(
          fields.imagePosition === 'left' && 'sm:w-1/2 left-0',
          fields.imagePosition === 'right' && 'sm:w-1/2 right-0',
          fields.imagePosition === 'background' && 'w-full left-0',
          fields.textPosition === 'under' ? 'h-[300px] md:h-[400px] lg:h-[500px]' : 'sm:absolute z-0 top-0 h-full',
          fields.imagePosition === 'background' && fields.textPosition !== 'under' && 'absolute'
        )}
      >
        <LossePlaatjie
          src={fields.image}
          className={clsx(
            'w-full h-full object-cover block  containerize sm:max-h-min',
            fields.imagePosition !== 'background' && 'max-h-[350px]',
            fields.imagePosition === 'left' && 'max-w-5xl ml-auto',
            fields.imagePosition === 'right' && 'max-w-5xl mr-auto'
          )}
          maxwidth={1280}
        />
      </div>
      {fields.imagePosition === 'background' && fields.textPosition !== 'under' && (
        <div className="absolute z-20 inset-0 bg-gradient-to-b sm:bg-gradient-to-r from-transparent to-black" />
      )}
      <div className={clsx('container relative z-20', fields.textPosition === 'under' && 'pb-24 max-w-screen-xl')}>
        <div className="grid sm:grid-cols-2">
          {(fields.imagePosition === 'left' || fields.imagePosition === 'background') && fields.textPosition !== 'under' && (
            <div className="col-span-1" />
          )}
          <div
            className={clsx(
              'flex items-center',
              fields.imagePosition === 'background' && fields.textPosition !== 'under' && 'md:min-h-[650px]',
              fields.textPosition === 'under' ? 'col-span-2 bg-hh-mine-shaft -mt-20' : 'col-span-1'
            )}
          >
            <div
              className={clsx(
                'section',
                fields.imagePosition === 'left' && 'sm:pl-12 xl:pl-24',
                fields.imagePosition === 'right' && 'sm:pr-12 xl:pr-24',
                fields.textPosition === 'under' && 'px-8 sm:px-12 xl:px-24'
              )}
            >
              <Content
                className={clsx(
                  'content-lg',
                  fields.imagePosition !== 'background' && 'text-hh-mine-shaft',
                  (fields.imagePosition === 'background' || fields.backgroundColor === 'mineshaft') && 'text-white',
                  fields.textPosition !== 'under' && 'max-w-lg'
                )}
              >
                {fields.description}
              </Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
