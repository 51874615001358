import { useSearchParams } from '@ubo/losse-sjedel'
import { useLosseBlogBink } from '@ubo/losse-sjedel'
import type { ChangeEvent } from 'react'

export default function Ordering() {
  const [searchParams] = useSearchParams()
  const { setOrder } = useLosseBlogBink()

  return (
    <div className="flex justify-end items-center">
      <span className="font-hh-form font-light text-hh-emperor sm:text-base text-sm pr-4">Sort by</span>
      <select
        name="_s"
        defaultValue={searchParams.get('_s') || 'date_desc'}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setOrder(e.target.value)
        }}
        className="select"
      >
        <option value="date_desc">Date desc</option>
        <option value="date_asc">Date asc</option>
        <option value="title_asc">Name (a-z)</option>
        <option value="title_desc">Name (z-a)</option>
      </select>
    </div>
  )
}
