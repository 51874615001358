import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import OverviewDefault from './OverviewDefault'
import OverviewProducts from './OverviewProducts'
import OverviewSimple from './OverviewSimple'

const OverviewShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: OverviewDefault,
    products: OverviewProducts,
    simple: OverviewSimple
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default OverviewShell
