import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseBlogBink, LosseLink, LossePlaatjie, useLoaderData, useLosseBlogBink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Ordering from '~/components/elements/blog/Ordering'
import Pagination from '~/components/elements/blog/Pagination'
import Content from '~/components/elements/Content'
import Message from '~/components/elements/Message'
import type {
  MarketToCategoryConnection,
  Maybe,
  Page_Flexcontent_Flex_RelatedProducts,
  Product,
  RootQueryToProductConnection
} from '~/graphql/types'
import { ManifestoApproved } from '../Overview/default-item-types/ProductItem'
import type { Edge } from '../Overview/OverviewDefault'

interface RelatedProductsProps extends Page_Flexcontent_Flex_RelatedProducts {
  subdata: {
    RelatedProducts: Maybe<RootQueryToProductConnection>
    RelatedProductsCategories: Maybe<MarketToCategoryConnection>
  }
}

export default function RelatedProducts({ fields }: { fields: RelatedProductsProps }) {
  if (!fields.subdata.RelatedProducts?.pageInfo) {
    return null
  }

  return (
    <section id="OverviewProducts">
      <LosseBlogBink pageInfo={fields.subdata.RelatedProducts.pageInfo}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

function Overview({ fields }: { fields: RelatedProductsProps }) {
  const { isFilterActive, toggleFilter, hasNextPage, hasPrevPage, clear } = useLosseBlogBink()
  const { page } = useLoaderData<LoaderData>()
  const products = fields.subdata.RelatedProducts?.edges as Edge<Product>[]
  const filters = fields.subdata.RelatedProductsCategories?.nodes

  const isAnyFilterActive = filters?.some((category) => {
    return isFilterActive(category?.databaseId)
  })

  return (
    <div>
      <div className="section bg-hh-concrete">
        <div className="container max-w-screen-xl">
          <Content className="content mb-6">
            <h2>Related products for {page.title}</h2>
          </Content>
          <div className="flex items-center flex-wrap sm:gap-4 gap-2">
            <span className="font-hh-form text-sm">Filter by category</span>

            <button
              onClick={() => clear()}
              className={clsx(
                'px-4 py-2 rounded-full sm:text-base text-sm font-hh-heading text-white',
                isAnyFilterActive ? 'bg-hh-gray' : 'bg-hh-blue'
              )}
              type="button"
            >
              <span className="leading-none mt-[-1px] block">Select all</span>
            </button>

            {filters?.map((category) => (
              <button
                onClick={() => toggleFilter(category?.databaseId)}
                className={clsx(
                  'px-4 py-2 rounded-full sm:text-base font-hh-heading text-white text-sm hover:bg-hh-denim button-base-ring focus:ring-offset-white focus:ring-hh-blue',
                  isFilterActive(category?.databaseId) ? 'bg-hh-blue' : 'bg-hh-gray'
                )}
                key={category?.databaseId}
                type="button"
              >
                <span className="leading-none mt-[-1px] block">{category?.name}</span>
              </button>
            ))}
          </div>

          <div className="mb-6 mt-10 flex items-center justify-between">
            <div className="title text-xl font-light">{fields.subdata.RelatedProducts?.pageInfo?.total} results</div>
            <Ordering />
          </div>

          <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 mb-6">
            {products.length === 0 && <Message>No products found</Message>}
            {products?.map((edge) => (
              <LosseLink
                to={edge.node.uri}
                key={edge?.node.databaseId}
                className="bg-white group p-6 relative flex flex-col justify-between"
              >
                {edge.node.recap?.manifestoApproved && (
                  <div className="absolute top-0 right-1 sm:right-5 bg-hh-green rounded-b-full pt-2 z-[1]">
                    <ManifestoApproved className="w-10 h-10 sm:w-16 sm:h-16 px-2" />
                  </div>
                )}

                {edge.node.recap?.image ? (
                  <LossePlaatjie
                    src={edge.node.recap?.image}
                    maxwidth={300}
                    className="mb-6 object-contain group-hover:scale-110 transition-all"
                  />
                ) : (
                  <div />
                )}

                <strong className="font-hh-heading sm:text-base text-sm uppercase group-hover:underline">{edge.node.title}</strong>
              </LosseLink>
            ))}
          </div>

          <div className="flex justify-between">
            <LosseLink to="/productcatalog-a-z" className="button-link flex items-center">
              <span className="pr-4">Product catalog A-Z</span>
              <img src="/icons/chevron-right-black.svg" className="" alt="Chevron right" />
            </LosseLink>

            {(hasNextPage || hasPrevPage) && <Pagination />}
          </div>
        </div>
      </div>
    </div>
  )
}
