import { useEffect } from "react";

export default function BootlegCookiebot() {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('id', 'Cookiebot');
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    script.setAttribute('data-cbid', '590a5817-d626-498d-a267-3f850637ae72');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');
    const head = document.querySelector('html > head');
    if (!head) return () => {};
    head.append(script);

    return () => {}
  }, [])

  return null
}
