import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import LinksDefault from './LinksDefault'
import LinksWithIconHorizontally from './LinksWithIconHorizontally'
import LinksWithIconVertically from './LinksWithIconVertically'

const LinksShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: LinksDefault,
    icon: LinksWithIconHorizontally,
    icon_vertical: LinksWithIconVertically
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default LinksShell
