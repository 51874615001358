import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Mousewheel } from 'swiper'

export default function SliderImages({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section>
      <div className="container section-text">
        <Content className="content-lg">{fields?.description}</Content>

        <div className="spacing-top">
          <Swiper
            modules={[Pagination, Navigation, Mousewheel]}
            pagination={{ type: 'progressbar' }}
            navigation
            breakpoints={{
              1280: {
                slidesPerView: 4
              },
              1024: {
                slidesPerView: 4
              },
              768: {
                slidesPerView: 3
              },
              640: {
                slidesPerView: 2
              },
              0: {
                slidesPerView: 1.5
              }
            }}
            mousewheel={{
              forceToAxis: true
            }}
          >
            {fields?.slides?.map((slide, index) => (
              <SwiperSlide key={index}>
                <LosseLink to={slide?.link?.url} target={slide?.link?.target as string} className="text-[0px] px-10">
                  <LossePlaatjie src={slide?.image} maxwidth={600} className="w-full h-20 md:h-24 object-contain grayscale" />
                  {slide?.link?.title}
                </LosseLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
