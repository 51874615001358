import * as React from 'react'
import * as THREE from 'three'
import ReactGlobeGl from 'react-globe.gl'
import { useSearchParams } from '@ubo/losse-sjedel'
import useMediaQuery from '~/hooks/useMediaQuery'
import type { Location_Recap, Maybe } from '~/graphql/types'

export interface Marker {
  id: string
  label: string
  hovered: boolean
  active: boolean
  lat: number
  lng: number
  recap: Maybe<Location_Recap> | undefined
}

interface GlobeProps {
  markers: Marker[]
  onClick: (marker: Marker) => void
}

const regionsWithCoords = {
  eu: {
    lat: 42.24295,
    lng: 5.37499
  },
  na: {
    lat: 40.0,
    lng: -100.0
  },
  sa: {
    lat: -10.0,
    lng: -60.0
  },
  afr: {
    lat: 6.490554,
    lng: 20.161381
  },
  asia: {
    lat: 30.0,
    lng: 100.0
  },
  me: {
    lat: 21.3134124,
    lng: 40.6560528
  },
  oce: {
    lat: -25.274398,
    lng: 133.77513599999997
  }
}

export default function Globe({ markers, onClick }: GlobeProps) {
  const [countries, setCountries] = React.useState({ features: [] })
  const ref = React.useRef<any>()
  const [searchParams] = useSearchParams()
  const xl = useMediaQuery('(min-width: 1280px)')
  const lg = useMediaQuery('(min-width: 1024px)')
  const md = useMediaQuery('(min-width: 768px)')

  let globeSize = 340

  if (md) {
    globeSize = 400
  }

  if (lg) {
    globeSize = 450
  }

  if (xl) {
    globeSize = 550
  }

  React.useEffect(() => {
    if (!ref.current) return
    ref.current.controls().enableZoom = false
    ref.current.controls().update()
  }, [])

  React.useEffect(() => {
    // load data
    fetch('/three/countries.geojson')
      .then((res) => res.json())
      .then(setCountries)

    if (!ref.current) return

    ref.current.pointOfView(
      {
        lat: 42.24295,
        lng: 5.37499,
        altitude: 1.75
      },
      0
    )
  }, [])

  const selectedParam = searchParams.get('selected')

  React.useEffect(() => {
    const marker = markers.find((marker) => marker.id === selectedParam)
    if (!marker) return
    if (!ref.current) return

    ref.current.pointOfView(
      {
        lat: marker.lat - 10,
        lng: marker.lng,
        altitude: 1.75
      },
      1000
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParam])

  const regionParam = searchParams.get('region')

  React.useEffect(() => {
    if (typeof regionParam !== 'string') return
    // @ts-ignore
    const selectedRegion = regionsWithCoords[regionParam] as {
      lat: number
      lng: number
    }
    if (!selectedRegion) return
    if (!ref.current) return

    ref.current.pointOfView(
      {
        lat: selectedRegion.lat,
        lng: selectedRegion.lng,
        altitude: 1.75
      },
      1000
    )
  }, [regionParam])

  return (
    <div className="rounded-full border-[2px] border-white mt-12 mx-auto" style={{ width: globeSize, height: globeSize }}>
      <ReactGlobeGl
        width={globeSize}
        height={globeSize}
        ref={ref}
        backgroundColor="rgba(0,0,0,0)"
        polygonsData={countries.features}
        globeMaterial={new THREE.MeshBasicMaterial({ color: '#9CA1A4' })}
        polygonCapColor={() => '#D8DCDD'}
        polygonSideColor={() => '#D8DCDD'}
        polygonStrokeColor={() => '#c1c3c3'}
        pointsData={markers}
        pointsTransitionDuration={0}
        // @ts-ignore
        pointColor={(point: Marker) => (point.active ? '#00A1FF' : '#545454')}
        pointRadius={() => 0.6}
        pointResolution={50}
        pointAltitude={0.1}
        // @ts-ignore
        pointLabel={(point: Marker) => `
          <div class="absolute">
            <div class="py-2 px-3 box-shadow bg-white relative w-[250px] left-[-47%]">
              <div class="${point.active ? `text-hh-blue` : `text-hh-mine-shaft`} font-hh-heading text-m font-bold">${point.label}</div>
              <hr className="border-black mt-2" />
              <div class="text-hh-mine-shaft mt-2 text-sm font-hh-paragrah font-normal">
                ${point.recap?.address ? `${point.recap?.address}<br />` : ''}
                ${point.recap?.country ? `${point.recap?.country}<br />` : ''}
                ${point.recap?.city ? `${point.recap?.city}` : ''}
              </div>
            </div>
          </div>
        `}
        onPointClick={(e: any) => {
          if (e) {
            onClick(e)
          }
        }}
      />
    </div>
  )
}
