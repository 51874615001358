import Content from '../Content'

export default function FormContent({ children, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...rest}>
      <Content className="content children-h2:text-2xl">{children}</Content>

      <div className="mt-6 text-s italic font-hh-form">Also keep an eye on your spam folder</div>
    </div>
  )
}
