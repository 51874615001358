import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Knowledge } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

const icons: { [key: string]: JSX.Element } = {
  Brochures: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
      <g clipPath="url(#clip0_789_4746)">
        <path
          d="M14.4137 0H4.99969C4.20405 0 3.44098 0.31607 2.87837 0.87868C2.31577 1.44129 1.99969 2.20435 1.99969 3V24H21.9997V7.586L14.4137 0ZM14.9997 3.414L18.5857 7H14.9997V3.414ZM3.99969 22V3C3.99969 2.73478 4.10505 2.48043 4.29259 2.29289C4.48012 2.10536 4.73448 2 4.99969 2H12.9997V9H19.9997V22H3.99969Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_789_4746">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Whitepapers: (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
      <path
        d="M16.0001 5.086L10.9141 0H3.00012C2.20447 0 1.44141 0.31607 0.878802 0.87868C0.316193 1.44129 0.00012207 2.20435 0.00012207 3V20H16.0001V5.086ZM2.00012 18V3C2.00012 2.73478 2.10548 2.48043 2.29302 2.29289C2.48055 2.10536 2.73491 2 3.00012 2H10.0001V6H14.0001V18H2.00012ZM20.0001 9V24H5.00012V22H18.0001V7L20.0001 9Z"
        fill="currentColor"
      />
    </svg>
  ),
  'Case reports': (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
      <path
        d="M19.25 2.7373H11.2163L7.54967 0.912598H2.75C2.02065 0.912598 1.32118 1.20097 0.805456 1.71426C0.289731 2.22756 0 2.92374 0 3.64966L0 20.9844H22V5.47436C22 4.74845 21.7103 4.05227 21.1945 3.53897C20.6788 3.02567 19.9793 2.7373 19.25 2.7373ZM2.75 2.7373H7.117L10.7837 4.56201H19.25C19.4931 4.56201 19.7263 4.65813 19.8982 4.82923C20.0701 5.00033 20.1667 5.23239 20.1667 5.47436V6.27815L1.83333 6.38124V3.64966C1.83333 3.40769 1.92991 3.17562 2.10182 3.00453C2.27373 2.83343 2.50689 2.7373 2.75 2.7373V2.7373ZM1.83333 19.1597V8.20595L20.1667 8.10285V19.1597H1.83333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default function KnowledgeBase({ fields }: { fields: Page_Flexcontent_Flex_Knowledge }) {
  return (
    <section>
      <div className="section container xl:max-w-screen-xl">
        {fields.description && <Content className="content-lg spacing-bottom">{fields?.description}</Content>}

        <div className="grid lg:grid-cols-3 gap-4 lg:gap-6">
          {fields.bases?.map((base) => (
            <div
              key={base?.title}
              className={clsx(
                'col-span-1 p-6 lg:p-8 xl:p-14 flex flex-col justify-between',
                fields.baseColor === 'concrete' && 'bg-hh-concrete text-hh-mine-shaft',
                fields.baseColor === 'mineshaft' && 'bg-hh-mine-shaft text-white'
              )}
            >
              <div>
                <h3 className="title text-1xl md:text-2xl uppercase">{base?.title}</h3>
                <div className="mt-5 md:mt-8 paragraph">
                  {base?.items?.map((item) => {
                    return (
                      <LosseLink
                        key={item?.uri}
                        to={item?.uri}
                        className={clsx(
                          'flex items-center mt-3 group',
                          fields.baseColor === 'concrete' && 'text-hh-mine-shaft/[0.5]',
                          fields.baseColor === 'mineshaft' && 'text-white/[0.5]'
                        )}
                      >
                        <span className="mr-2 lg:mr-5 min-w-[19px] min-h-[23px] max-w-[19px] max-h-[23px]">
                          {icons[base?.title as string] || icons.Whitepapers}
                        </span>
                        <span className="group-hover:underline title text-sm md:text-m lg:text-lg line-clamp-1">{item?.title}</span>
                        {item?.recap?.isNew && (
                          <span className="ml-3 block px-2 py-[1px] text-s font-bold lg:font-normal uppercase bg-hh-blue text-white">
                            New
                          </span>
                        )}
                      </LosseLink>
                    )
                  })}
                </div>
              </div>
              {base?.link && (
                <div className="mt-4 sm:spacing-top flex justify-end">
                  <LosseLink
                    to={base?.link?.url}
                    target={base?.link?.target as string}
                    className={clsx(
                      '-mr-4 lg:-mr-5 text-hh-mine-shaft paragraph flex items-center button-base hover:text-white hover:bg-hh-blue focus:ring-offset-white focus:ring-hh-blue',
                      fields.baseColor === 'concrete' && 'bg-hh-concrete text-hh-mine-shaft border-hh-concrete',
                      fields.baseColor === 'mineshaft' && 'bg-hh-mine-shaft text-white border-hh-mine-shaft'
                    )}
                  >
                    {base?.link?.title}
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3 w-3">
                      <path
                        d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
                        fill="#323232"
                      />
                    </svg>
                  </LosseLink>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
