import { useLoaderData } from '@ubo/losse-sjedel'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import type { LoaderData } from '@ubo/losse-sjedel'

export default function SocialSharing({ className = '' }) {
  const data = useLoaderData<LoaderData>()

  return (
    <div className={className}>
      <div className="text-white bg-hh-mine-shaft/20 hover:bg-hh-blue rounded-full h-5 w-5 md:h-7 md:w-7 flex items-center justify-center overflow-hidden">
        <EmailShareButton url={data.request.url} title={data.page.title || ''} className="w-full h-full">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-auto">
            <path
              d="M11.375 0.541992H1.625C1.19402 0.541992 0.780698 0.713197 0.475951 1.01794C0.171205 1.32269 0 1.73602 0 2.16699L0 12.4587H13V2.16699C13 1.73602 12.8288 1.32269 12.524 1.01794C12.2193 0.713197 11.806 0.541992 11.375 0.541992ZM1.625 1.62533H11.375C11.5187 1.62533 11.6564 1.68239 11.758 1.78398C11.8596 1.88556 11.9167 2.02333 11.9167 2.16699V2.52828L7.64942 6.79607C7.34415 7.10012 6.93085 7.27083 6.5 7.27083C6.06915 7.27083 5.65585 7.10012 5.35058 6.79607L1.08333 2.52828V2.16699C1.08333 2.02333 1.1404 1.88556 1.24198 1.78398C1.34357 1.68239 1.48134 1.62533 1.625 1.62533ZM1.08333 11.3753V4.06283L4.58467 7.56199C5.09309 8.06913 5.78189 8.35393 6.5 8.35393C7.21811 8.35393 7.90691 8.06913 8.41533 7.56199L11.9167 4.06283V11.3753H1.08333Z"
              fill="currentColor"
            />
          </svg>
        </EmailShareButton>
      </div>
      <div className="text-white bg-hh-mine-shaft/20 hover:bg-hh-blue rounded-full h-5 w-5 md:h-7 md:w-7 flex items-center justify-center overflow-hidden ml-3">
        <FacebookShareButton url={data.request.url} title={data.page.title || ''} className="w-full h-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="w-3 h-3 mx-auto"
            role="img"
            width="20"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="m279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
            ></path>
          </svg>
        </FacebookShareButton>
      </div>
      <div className="text-white bg-hh-mine-shaft/20 hover:bg-hh-blue rounded-full h-5 w-5 md:h-7 md:w-7 flex items-center justify-center overflow-hidden ml-3">
        <LinkedinShareButton url={data.request.url} title={data.page.title || ''} className="w-full h-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            className="w-3 h-3 mx-auto"
            width="33.48"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1046.16 1000"
          >
            <path
              fill="currentColor"
              d="M237.485 1000V325.301H13.229V1000h224.256zM125.386 233.127c78.202 0 126.879-51.809 126.879-116.553C250.808 50.37 203.591-.001 126.87-.001C50.161-.001-.002 50.371-.002 116.574c0 64.747 48.665 116.553 123.924 116.553h1.457zM361.61 1000h224.256V623.215c0-20.165 1.457-40.309 7.379-54.724c16.212-40.289 53.111-82.017 115.06-82.017c81.149 0 113.613 61.872 113.613 152.572v360.949h224.242V613.129c0-207.241-110.636-303.668-258.183-303.668c-120.977 0-174.094 67.622-203.603 113.679h1.497v-97.853H361.615c2.943 63.31 0 674.699 0 674.699z"
            ></path>
          </svg>
        </LinkedinShareButton>
      </div>
      <div className="text-white bg-hh-mine-shaft/20 hover:bg-hh-blue rounded-full h-5 w-5 md:h-7 md:w-7 flex items-center justify-center overflow-hidden ml-3">
        <TwitterShareButton url={data.request.url} title={data.page.title || ''} className="w-full h-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            className="w-3 h-3 mx-auto"
            width="39.4"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1231.051 1000"
          >
            <path
              fill="currentColor"
              d="M1231.051 118.453q-51.422 76.487-126.173 130.403q.738 14.46.738 32.687q0 101.273-29.53 202.791q-29.53 101.519-90.215 194.343q-60.685 92.824-144.574 164.468q-83.889 71.644-201.677 114.25q-117.788 42.606-252.474 42.606q-210.2 0-387.147-113.493q31.406 3.495 60.242 3.495q175.605 0 313.687-108.177q-81.877-1.501-146.654-50.409q-64.777-48.907-89.156-124.988q24.097 4.59 47.566 4.59q33.782 0 66.482-8.812q-87.378-17.5-144.975-87.04q-57.595-69.539-57.595-160.523v-3.126q53.633 29.696 114.416 31.592q-51.762-34.508-82.079-89.999q-30.319-55.491-30.319-120.102q0-68.143 34.151-126.908q95.022 116.607 230.278 186.392q135.258 69.786 290.212 77.514q-6.609-27.543-6.621-57.485q0-104.546 73.994-178.534Q747.623 0 852.169 0q109.456 0 184.392 79.711q85.618-16.959 160.333-61.349q-28.785 90.59-110.933 139.768q75.502-8.972 145.088-39.677z"
            ></path>
          </svg>
        </TwitterShareButton>
      </div>
    </div>
  )
}
