import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'
import EndlessContainer from '~/components/elements/EndlessContainer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { motion } from 'framer-motion'
import { Pagination, Navigation, Mousewheel } from 'swiper'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'

export default function SliderPosts({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section>
      <div className="section">
        <EndlessContainer bodyElemenyById="main-site">
          <div className="sm:grid grid-cols-5 lg:grid-cols-4 gap-5">
            <div className="col-span-2 lg:col-span-1 py-10">
              <Content className="content-lg">{fields.description}</Content>
            </div>
            <div className="col-span-3">
              <Swiper
                modules={[Pagination, Navigation, Mousewheel]}
                mousewheel={{
                  forceToAxis: true
                }}
                navigation
                breakpoints={{
                  1536: {
                    slidesPerView: 4,
                    spaceBetween: 15
                  },
                  1280: {
                    slidesPerView: 3.5,
                    spaceBetween: 15
                  },
                  1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 15
                  },
                  768: {
                    slidesPerView: 1.75,
                    spaceBetween: 15
                  },
                  640: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                  },
                  0: {
                    slidesPerView: 1.5,
                    spaceBetween: 15
                  }
                }}
                pagination={{ type: 'progressbar' }}
              >
                {fields.posts?.map((post, index) => (
                  <SwiperSlide key={index}>
                    <motion.div whileHover="hover">
                      <LosseLink
                        className="bg-hh-silver w-full flex items-end relative h-[400px] lg:h-[500px] overflow-hidden"
                        to={post?.uri as string}
                      >
                        <div className="absolute inset-0 z-0">
                          <LossePlaatjie src={post?.recap?.image} maxwidth={600} className="w-full h-full object-cover" />
                        </div>
                        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black/25" />
                        <motion.div
                          initial={{
                            opacity: 1
                          }}
                          variants={{
                            hover: {
                              opacity: 0
                            }
                          }}
                          transition={{
                            duration: 0.2
                          }}
                          className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black"
                        />
                        <motion.div
                          initial={{
                            opacity: 0,
                            scale: 1.1
                          }}
                          variants={{
                            hover: {
                              opacity: 1,
                              scale: 1
                            }
                          }}
                          transition={{
                            duration: 0.2
                          }}
                          className="absolute inset-0 z-10 flex items-center justify-center"
                        >
                          <div className="absolute inset-0 z-0 bg-black opacity-50" />
                          {(post?.__typename === 'Post' || post?.__typename === 'Market') && (
                            <LossePlaatjie maxwidth={600} src={post?.recap?.icon} className="relative z-10 h-36 w-36 object-contain" />
                          )}
                        </motion.div>
                        <div className="relative w-full z-20 px-4 py-5 sm:px-7 sm:py-10 text-white">
                          <div className="flex items-center mb-4 overflow-x-auto no-scrollbar h-[26px] -mr-3 md:-mr-5 xl:-mr-7">
                            {post?.categories?.nodes?.map((category) => (
                              <div key={category?.databaseId} className="tag mr-2">
                                {category?.name}
                              </div>
                            ))}
                          </div>
                          <h2 className="title text-base sm:text-2xl break-words line-clamp-4">{post?.title}</h2>
                          <div className="flex items-center justify-between mt-2 text-white/80">
                            <h3 className="paragraph">{post?.__typename}</h3>
                            <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-3 w-3">
                              <path
                                d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                      </LosseLink>
                    </motion.div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </EndlessContainer>
      </div>
    </section>
  )
}
