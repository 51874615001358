import React from 'react'

// Components
import ExhibitionsDefault from './ExhibitionsDefault'
import ExhibitionsOverview from './ExhibitionsOverview'

export interface ExhibitionsProps {
  fields: any
  index: number
}

interface Props {
  [key: string]: React.FC<ExhibitionsProps>
}

const ExhibitionsShell: React.FC<ExhibitionsProps> = ({ fields, index }) => {
  const types: Props = {
    default: ExhibitionsDefault,
    overview: ExhibitionsOverview
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return <Component key={index} index={index} fields={fields} />
}

export default ExhibitionsShell
