import { useLoaderData, useNavigation, pageLoader as loader } from '@ubo/losse-sjedel'
import { type LoaderData } from '@ubo/losse-sjedel'
import Progress from 'nprogress'
import { useEffect } from 'react'

import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'

export { loader }

export default function $page() {
  const { page } = useLoaderData<LoaderData>()

  const navigation = useNavigation()

  useEffect(() => {
    if (navigation.state === 'loading' || navigation.state === 'submitting') {
      Progress.start()
    } else {
      Progress.done()
    }
  }, [navigation.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${page?.contentType?.node?.name}_Flexcontent`} flex={page?.flexContent?.flex} />
    </Layout>
  )
}
