import React from 'react'

// Components
import SearchAZ from './SearchAZ'
import SearchDefault from './SearchDefault'

export interface SearchProps {
  fields: any
  index: number
}

interface Props {
  [key: string]: React.FC<SearchProps>
}

const SearchShell: React.FC<SearchProps> = ({ fields, index }) => {
  const types: Props = {
    default: SearchDefault,
    a_to_z: SearchAZ
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return <Component key={index} index={index} fields={fields} />
}

export default SearchShell
