import React from 'react'
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import styleToJsx from 'style-to-js'

// .button-blue
// .button-mine-shaft-opaque
// .button-white-opaque

const Content: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ ...rest }) => {
  return (
    <LosseContent
      {...rest}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        if (name === 'table') {
          return (
            <div className="table-hack-wrapper">
              <div className="table-hack-cell">
                <table>{losseContentToReact(children, {})}</table>
              </div>
            </div>
          )
        }

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attributes && attributes.style) {
            attributes.style = styleToJsx(attributes.style)
          }

          if (attribs.class === 'button') {
            return (
              <Button href={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children as string}
    </LosseContent>
  )
}

export default Content
