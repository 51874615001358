import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Exhibition, Page_Flexcontent_Flex_Exhibitions } from '~/graphql/types'
import { LosseBlogBink, LossePlaatjie, LosseLink, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import Pagination from '~/components/elements/blog/Pagination'
import dayjs from 'dayjs'
import Message from '~/components/elements/Message'

export default function ExhibitionsOverview({ fields }: { fields: Page_Flexcontent_Flex_Exhibitions }) {
  if (!fields.exhibitions?.pageInfo) return null

  return (
    <section className={clsx('section', fields.backgroundColor === 'concrete' && 'bg-hh-concrete')}>
      <LosseBlogBink pageInfo={fields.exhibitions?.pageInfo}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Exhibitions }) {
  const { hasNextPage, hasPrevPage } = useLosseBlogBink()
  const [searchParams, setSearchParams] = useSearchParams()
  const items = fields.exhibitions?.edges as { node: Exhibition }[]

  return (
    <div className="container xl:max-w-screen-xl">
      {fields.description && <Content className="content">{fields.description}</Content>}

      {fields.filters && fields.filters.length > 0 && (
        <select
          onChange={(e) => {
            searchParams.set('_f', e.target.value)
            setSearchParams(searchParams, {
              replace: true,
              state: {
                scroll: false
              }
            })
          }}
          className="select mt-4"
          name="year"
          defaultValue={searchParams.get('_f') || undefined}
        >
          <option value="">Choose a year</option>
          {fields.filters?.map((filter) => {
            return (
              <option key={filter?.databaseId} value={filter?.databaseId}>
                {filter?.name}
              </option>
            )
          })}
        </select>
      )}

      <div className="md:mt-12 mt-8 grid sm:grid-cols-3 grid-cols-2 md:gap-8 gap-4">
        {items.length === 0 && <Message className="col-span-3">No exhibitions found</Message>}
        {items
          ?.sort(
            (a, b) =>
              Number(dayjs(a.node.recap?.dateStart, 'DD/MM/YY').format('MMDD')) -
              Number(dayjs(b.node.recap?.dateStart, 'DD/MM/YY').format('MMDD'))
          )
          ?.map((item) => (
            <LosseLink key={item?.node?.id} to={item?.node?.uri} className="col-span-1 shadow bg-white lg:p-6 p-4 group">
              <div className="flex">
                <span className="label lowercase text-[10px] sm:text-s lg:text-sm flex flex-wrap sm:w-auto group-hover:bg-hh-denim transition-colors">
                  <span>{dayjs(item.node.recap?.dateStart, 'DD/MM/YY').format('DD MMM')}</span>
                  <span className="px-1">{' - '}</span>
                  <span>{dayjs(item.node.recap?.dateEnd, 'DD/MM/YY').format('DD MMM')}</span>
                </span>
              </div>
              <LossePlaatjie
                maxwidth={500}
                src={item?.node?.recap?.image}
                className="xl:my-8 my-6 h-[150px] w-full object-contain group-hover:scale-105 transition-all"
              />
              <h3 className="title uppercase xl:text-2xl lg:text-1xl text-lg group-hover:underline">{item.node.title}</h3>
              <h4 className="title xl:text-1xl lg:text-xl text-base font-light mt-2">{item.node.recap?.location}</h4>
            </LosseLink>
          ))}
      </div>

      {(hasNextPage || hasPrevPage) && <Pagination />}
    </div>
  )
}
