import React from 'react'
import clsx from 'clsx'
import { LosseLayout, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import NewsLetter from './NewsLetter'
import QuickSupport from './QuickSupport'
import BootlegCookiebot from '../BootlegCookiebot'
import { ClientOnly } from '../elements/ClientOnly'

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Content>{children}</Content>
    </LosseLayout>
  )
}

function Content({ children }: React.PropsWithChildren<{}>) {
  const { isScrollable, isSticky } = useLosseLayout()

  return (
    <div className={clsx(isScrollable ? `h-full` : `h-screen overflow-hidden`)}>
      <Header />
      <main
        id="main-site"
        className={clsx(
          'containerize relative min-h-screen pt-[75px] transition-transform',
          !isSticky && 'lg:pt-[175px]',
          isSticky && 'lg:pt-[125px]'
        )}
      >
        {children}
      </main>
      <NewsLetter />
      <Footer />
      <QuickSupport />

      <ClientOnly>{() => <BootlegCookiebot />}</ClientOnly>
    </div>
  )
}
