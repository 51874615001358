import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Knowledge } from '~/graphql/types'
import { Pagination, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LossePlaatjie, LosseLink } from '@ubo/losse-sjedel'

export default function KnowledgeDefault({ fields }: { fields: Page_Flexcontent_Flex_Knowledge }) {
  return (
    <section>
      <div className="container max-w-screen-lg section">
        <Swiper
          modules={fields.items && fields.items?.length > 1 ? [Pagination, Mousewheel] : []}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          mousewheel={{
            forceToAxis: true
          }}
        >
          {fields.items?.map((edge, index) => (
            <SwiperSlide key={index}>
              <Content className="content-lg text-hh-mine-shaft opacity-10">{`<h2>${fields.title}</h2>`}</Content>
              <div className="bg-hh-mine-shaft p-5 md:px-10 lg:px-20 md:py-10 lg:pt-20 lg:pb-14 mt-5 lg:mt-6">
                <div className="grid grid-cols-6 lg:grid-cols-5 gap-5 xl:gap-10">
                  <div className="col-span-4 lg:col-span-3 text-white">
                    <Content className="content-lg text-white">{`<h3>${edge?.title}</h3>`}</Content>
                    <Content className="content opacity-80 mt-7">{edge?.recap?.description}</Content>
                    <div className="mt-7 hidden items-center md:flex">
                      <LosseLink className="button-blue" to={edge?.uri}>
                        {fields.readMoreText}
                      </LosseLink>
                      {fields.linkToOverview && <LosseLink className="button-white-opaque ml-3" src={fields.linkToOverview} />}
                    </div>
                  </div>
                  <div className="col-span-2 relative">
                    <LossePlaatjie
                      maxwidth={400}
                      src={edge?.recap?.image}
                      className="absolute bg-white max-h-[350px] bottom-7 right-0 w-auto object-contain knowledge-image"
                    />
                  </div>
                </div>
                <div className="mt-7 flex items-center md:hidden">
                  <LosseLink className="button-blue" to={edge?.uri}>
                    {fields.readMoreText}
                  </LosseLink>
                  {fields.linkToOverview && <LosseLink className="button-white-opaque ml-3" src={fields.linkToOverview} />}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
