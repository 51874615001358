import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import InfoBlocksDefault from './InfoBlocksDefault'

const InfoBlocksShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: InfoBlocksDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default InfoBlocksShell
