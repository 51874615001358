import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import clsx from 'clsx'
import { classes } from './TextShell'

export default function TextSplit({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section className={classes(fields)}>
      <div className="container xl:max-w-screen-xl">
        <div className={clsx('grid lg:grid-cols-3 gap-10 lg:gap-20 content', fields.backgroundColor === 'mineshaft' && 'text-white')}>
          <Content className="order-3 lg:order-1 lg:col-span-2">{fields?.description}</Content>
          {fields?.descriptionRight && <Content className="order-2 col-span-1">{fields?.descriptionRight}</Content>}
        </div>
      </div>
    </section>
  )
}
