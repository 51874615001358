import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import SliderPosts from './SliderPosts'
import SliderPostTypes from './SliderPostTypes'
import SliderImages from './SliderImages'
import SliderPostTypesNoIcon from './SliderPostTypesNoIcon'

const SliderShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    posts: SliderPosts,
    post_types: SliderPostTypes,
    images: SliderImages,
    post_types_no_icon: SliderPostTypesNoIcon
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default SliderShell
