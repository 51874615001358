import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Slider, Reference } from '~/graphql/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Autoplay } from 'swiper'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function SliderPostTypesNoIcon({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const references = fields?.posts as Reference[]
  return (
    <section className="section">
      <div className="container xl:mb-16 mb-10">
        <Content className="content-lg text-center">{`<h2>${fields.title}</h2>`}</Content>
      </div>
      <Swiper modules={[FreeMode, Autoplay]} autoplay={{ delay: 5000 }} spaceBetween={15} slidesPerView="auto">
        {references.map((post) => (
          <SwiperSlide className="xl:!w-[440px] sm:!w-[300px] !w-[175px]" key={post?.databaseId}>
            <LosseLink to={post?.uri}>
              <LossePlaatjie src={post?.recap?.image} maxwidth={600} className="w-full xl:h-[300px] sm:h-[220px] h-[125px] object-cover" />
              <div className="bg-black text-white sm:px-8 px-4 py-2 flex flex-col font-hh-heading">
                <span className="sm:text-xl text-base mb-1 font-medium mt-1">{post?.title}</span>
                <span className="sm:text-base text-sm font-light mb-[6px]">
                  {post.categories?.nodes?.map((category) => category?.name).join(', ')}
                </span>
              </div>
            </LosseLink>
          </SwiperSlide>
        ))}
      </Swiper>

      {fields.cta && (
        <div className="container flex flex-col items-center xl:mt-16 mt-10">
          <LosseLink src={fields.cta} className="button-mine-shaft-opaque" />
        </div>
      )}
    </section>
  )
}
