import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import clsx from 'clsx'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { classes } from './TextShell'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const { page } = useLoaderData<LoaderData>()

  return (
    <section className={classes(fields)}>
      <div className={clsx('container', page?.contentType?.node?.name === 'product' ? 'xl:max-w-screen-lg' : 'xl:max-w-screen-xl')}>
        <div className="grid grid-cols-3 gap-x-10">
          <div className={clsx(fields.imageBreadcrumb ? 'col-span-2' : 'col-span-3')}>
            <Content className="content">{fields?.title}</Content>

            <div className={clsx(fields.description ? 'my-5' : 'mt-5')}>
              <Breadcrumbs inBanner={fields.backgroundColor === 'mineshaft'} />
            </div>

            <Content className="content">{fields?.description}</Content>
          </div>

          {fields.imageBreadcrumb && <LossePlaatjie src={fields.imageBreadcrumb} maxwidth={600} />}
        </div>
      </div>
    </section>
  )
}
