import type { DynamicMenuEdges } from './HeaderDynamic'
import { LosseLink, useLocation } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
interface DynamicSubmenuProps {
  filteredItems?: DynamicMenuEdges
  menu: {
    current: number
    submenu: number
  }
}

export default function DynamicSubmenuProducts({ filteredItems, menu }: DynamicSubmenuProps) {
  const location = useLocation()

  if (!filteredItems || Object.keys(filteredItems).length === 0) {
    return null
  }

  console.log('productitems', filteredItems)

  if (location.pathname.includes('/tr/')) {
    const excludedTitles = [
      'Diffusers',
      'HVAC Duct Cleaning',
      'Fan Coil Unit',
      'Cruise Fan Coil Unit Enigma LT',
      'Plug & Play HVAC Systems',
      'Fan Coil Units (for Navy)',
      "Centrifugal Fans (GTL-3)",
      "Centrifugal Fans (GTL-1)",
      "Centrifugal fans (WPM and WPMA)",
      "WMR Duct Fan",
      "Duct Fans RS and ERM-EX",
      "AXC Duct Fans",
      "Centrifugal Fans (ENG and DNG)",
      "Axial Fans (WMOR and WMOD)",
      "Axial Fans (IGW)",
      "Axial Fans (HUA and VLA)"
    ];

    filteredItems = filteredItems?.filter(
      (item) => !excludedTitles.includes(item?.node?.title)
    );
  }


  return (
    <div className="grid grid-cols-4 lg:grid-cols-2 gap-2 lg:gap-8">
      <AnimatePresence exitBeforeEnter>
        {filteredItems?.map((f, index) => {
          return (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={`pl-4 first:pt-4 lg:p-0 col-span-4 lg:col-span-2 group`}
              key={`dynamic-submenu-${menu.submenu}-${index}`}
              id={`dynamic-submenu-${menu.submenu}-${index}`}
            >
              <LosseLink to={f?.node?.uri}>
                <div className={`group-hover:underline text-black lg:text-base text-left text-lg font-light normal-case font-hh-form`}>
                  {f?.node?.title}
                </div>
              </LosseLink>
            </motion.div>
          )
        })}
      </AnimatePresence>
    </div>
  )
}
