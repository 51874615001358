import type { Page_Flexcontent_Flex_LatestPosts } from '~/graphql/types'
import SliderPostTypes from '../Slider/SliderPostTypes'

export default function LatestPosts({ fields }: { fields: Page_Flexcontent_Flex_LatestPosts }) {
  // @ts-ignore
  const posts = fields?.subdata?.RelatedLatestPosts?.nodes || []

  if (!posts || posts.length === 0) {
    return null
  }

  return (
    <SliderPostTypes
      key={fields.__typename}
      // @ts-ignore
      fields={{
        ...{
          startingPoint: 'container',
          backgroundColor: 'white',
          posts
        },
        ...fields
      }}
    />
  )
}
