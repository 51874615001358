import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Maybe, Page_Flexcontent_Flex_History, Page_Flexcontent_Flex_History_Items } from '~/graphql/types'
import clsx from 'clsx'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useInView } from 'react-cool-inview'

export default function HistoryDefault({ fields }: { fields: Page_Flexcontent_Flex_History }) {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section className="section bg-hh-mine-shaft">
      <div className="container lg:max-w-screen-lg">
        <div className="sm:h-[750px] sm:grid grid-cols-12 gap-x-8">
          <div className="col-span-3 md:col-span-4 hidden sm:flex flex-col justify-between relative h-full">
            <div className="absolute w-[1px] bg-white inset-y-0 md:left-1/2 right-[15px]" />
            {fields?.items?.map((history, index) => (
              <Date key={history?.date} index={index} activeIndex={activeIndex} history={history} />
            ))}
          </div>
          <div className="col-span-9 md:col-span-8 snap-y relative snap-mandatory overflow-scroll h-full">
            {fields?.items?.map((history, index) => (
              <Event key={history?.date} index={index} history={history} setActiveIndex={setActiveIndex} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

function Date({
  index,
  activeIndex,
  history
}: {
  index: number
  activeIndex: number
  history: Maybe<Page_Flexcontent_Flex_History_Items>
}) {
  const odd = index % 2 === 0
  const active = index === activeIndex

  return (
    <div className={clsx(!active && 'text-white/30', active && 'text-hh-blue', 'snap-start h-full title lg:text-m text-sm')}>
      <AnimatePresence exitBeforeEnter>
        {active ? (
          <motion.div
            exit={{ scale: 0.95 }}
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.3
            }}
            key="active"
            className={clsx(
              !odd && 'md:mr-0 md:pr-0 md:ml-auto md:text-left md:pl-10',
              'mr-auto text-right pr-12 md:pr-10 md:w-1/2 relative pt-[3px] md:pt-0'
            )}
          >
            {history?.date}
            <LossePlaatjie
              src={history?.image}
              maxWidth={650}
              className={clsx(
                !odd && 'md:right-auto md:-left-[20px]',
                '-right-[5px] md:-right-[20px] h-[40px] w-[40px] -top-[7px] object-cover absolute rounded-full ring-1 ring-offset-2 ring-offset-hh-mine-shaft ring-hh-blue'
              )}
            />
          </motion.div>
        ) : (
          <motion.div
            exit={{ scale: 0.95 }}
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.3
            }}
            key="inactive"
            className={clsx(!odd && 'md:mr-0 md:pr-0 md:ml-auto md:text-left md:pl-4', 'mr-auto text-right pr-7 md:pr-4 md:w-1/2 relative')}
          >
            {history?.date}
            <div
              className={clsx(
                !odd && 'md:right-auto md:-left-[3px]',
                'right-[13px] md:-right-[4px] bg-white w-[7px] h-[7px] top-[7px] lg:top-[10px] rounded-full absolute'
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function Event({
  index,
  history,
  setActiveIndex
}: {
  index: number
  history: Maybe<Page_Flexcontent_Flex_History_Items>
  setActiveIndex: (value: number) => void
}) {
  const { observe } = useInView<HTMLDivElement>({
    threshold: 0.5,
    onEnter: () => {
      setActiveIndex(index)
    }
  })

  return (
    <div ref={observe} className="snap-start h-full mb-10 sm:mb-0">
      <hr className="sm:hidden border-hh-gray border-[1px] mb-10" />
      <LossePlaatjie src={history?.image} maxWidth={650} className="h-[200px] md:h-[300px] w-full object-cover relative z-0" />
      <div className="text-white mt-5">
        <h2 className="title lg:text-2xl uppercase sm:mb-2">{history?.title}</h2>
        <h3 className="title text-sm text-hh-blue sm:text-white">{history?.date}</h3>
        <Content className="content mt-2 text-white/[0.75]">{history?.description}</Content>
      </div>
    </div>
  )
}
