/* eslint-disable react-hooks/exhaustive-deps */
import type { Location, Page, Page_Flexcontent_Flex_Locations } from '~/graphql/types'
import { useEffect, useRef, useState } from 'react'
import Globe, { type Marker } from '~/components/elements/locations/Globe.client'
import { Form, useLoaderData, useNavigate, useSearchParams, useSubmit, useNavigation } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { LoaderData } from '@ubo/losse-sjedel'
import { isHq, mapToMarkers } from './Locations'
import { ClientOnly } from '../ClientOnly'

interface SearchableProps {
  locations: Location[]
  searchbar: Page_Flexcontent_Flex_Locations['searchbar']
  filterWords: Page_Flexcontent_Flex_Locations['filterWords']
  readMore: Page_Flexcontent_Flex_Locations['readMore']
}

interface Params {
  selected?: string
}

export default function Searchable(props: SearchableProps) {
  const data = useLoaderData<LoaderData<Page>>()
  const [searchParams] = useSearchParams()
  const selected = searchParams.get('selected')
  const [params, setParams] = useState<Params>({
    selected: selected || undefined
  })
  const navigate = useNavigate()
  const formRef = useRef<HTMLFormElement>(null)
  const submit = useSubmit()
  const navigation = useNavigation()
  const resultsRef = useRef<HTMLDivElement>(null)

  const filteredLocations = props.locations.filter((location) => {
    if (!location) return false
    if (!location.title) return false

    const search = searchParams.get('search-locations')?.toLowerCase()

    if (typeof search !== 'string') return true

    let found = false

    if (location.title.toLowerCase().includes(search)) {
      found = true
    }

    if (location.recap?.city?.toLowerCase().includes(search)) {
      found = true
    }

    if (location.recap?.country?.toLowerCase().includes(search)) {
      found = true
    }

    if (`${location.recap?.city} ${location.recap?.country}`.toLowerCase().includes(search)) {
      found = true
    }

    if (`${location.recap?.country} ${location.recap?.city}`.toLowerCase().includes(search)) {
      found = true
    }

    if (`${location.recap?.city}, ${location.recap?.country}`.toLowerCase().includes(search)) {
      found = true
    }

    return found
  })

  const markers = mapToMarkers(filteredLocations, searchParams)

  function onMarkerClick(marker: Marker | Location) {
    if (params.selected !== marker.id) {
      setParams({
        selected: marker.id
      })
    }
  }

  useEffect(() => {
    if (typeof searchParams.get('search-locations') === 'string' && filteredLocations.length > 0) {
      setParams({
        selected: filteredLocations[0].id
      })
    }
  }, [searchParams.get('search-locations')])

  useEffect(() => {
    if (formRef.current && params.selected) {
      submit(formRef.current)
    }
  }, [params.selected])

  useEffect(() => {
    if (navigation.state === 'idle' && params.selected && resultsRef.current) {
      window.scrollTo({
        top: resultsRef.current?.offsetTop - 200 || 0,
        behavior: 'smooth'
      })
    }
  }, [navigation])

  const locations = filteredLocations.sort((a: Location, b: Location) => {
    if (!a) return 0
    if (!b) return 0
    if (!a.title) return 0
    if (!b.title) return 0

    if (!selected && isHq(a) && !isHq(b)) {
      return -1
    }

    if (a.id === selected && b.id !== selected) {
      return -1
    }

    if (b.id === selected && a.id !== selected) {
      return 1
    }

    if (a.title.toLowerCase() > b.title.toLowerCase()) return 0

    return -1
  }) as Location[]

  return (
    <section className="section bg-hh-mine-shaft text-white">
      <div className="container max-w-screen-xl mx-auto grid lg:grid-cols-2 gap-x-6">
        <div className="lg:col-span-2 flex justify-center sm:mb-16 mb-8">
          <Form ref={formRef} action={new URL(data.request.url).pathname} method="get" className="relative flex">
            <input
              type="search"
              className="bg-white text-sm xl:w-[450px] font-hh-form font-light placeholder:font-hh-form placeholder:font-normal placeholder:text-hh-gray placeholder:text-opacity-50 text-black py-2 px-12 shadow-hh-inner border-white border-[1px] border-solid rounded-full"
              name="search-locations"
              placeholder={props.searchbar?.placeholder || 'Search'}
              defaultValue={searchParams.get('search-locations') || undefined}
            />
            <svg
              className="absolute top-0 left-4 bottom-0 my-auto"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 15.0586L11.8309 10.8895C12.9139 9.56498 13.4463 7.8749 13.3181 6.16882C13.19 4.46274 12.4109 2.8712 11.1422 1.72339C9.87348 0.575578 8.21211 -0.0406786 6.50176 0.00208561C4.79141 0.0448498 3.16292 0.743363 1.95314 1.95314C0.743363 3.16292 0.0448498 4.79141 0.00208561 6.50176C-0.0406786 8.21211 0.575578 9.87348 1.72339 11.1422C2.8712 12.4109 4.46274 13.19 6.16882 13.3181C7.8749 13.4463 9.56498 12.9139 10.8895 11.8309L15.0586 16L16 15.0586ZM6.6791 12.0053C5.62567 12.0053 4.5959 11.6929 3.72 11.1077C2.84411 10.5224 2.16143 9.6906 1.7583 8.71736C1.35517 7.74412 1.2497 6.67319 1.45521 5.64C1.66072 4.60681 2.168 3.65777 2.91288 2.91288C3.65777 2.168 4.60681 1.66072 5.64 1.45521C6.67319 1.2497 7.74412 1.35517 8.71736 1.7583C9.6906 2.16143 10.5224 2.84411 11.1077 3.72C11.6929 4.5959 12.0053 5.62567 12.0053 6.6791C12.0037 8.09121 11.4421 9.44504 10.4436 10.4436C9.44504 11.4421 8.09121 12.0037 6.6791 12.0053Z"
                fill="#888888"
                fillOpacity="1"
              />
            </svg>

            <input type="hidden" name="selected" value={params.selected} />
            <button className="button-blue -ml-10 py-1" type="submit">
              {props.searchbar?.buttonLabel}
            </button>
          </Form>
        </div>
        <div className="lg:mt-0 -mt-10">
          <ClientOnly>{() => <Globe onClick={onMarkerClick} markers={markers} />}</ClientOnly>
        </div>

        <div className="max-w-lg mx-auto w-full" ref={resultsRef}>
          <div className="font-hh-heading sm:text-2xl text-xl mb-4">
            {locations.length} {props.filterWords?.resultsWord}
          </div>

          <div className="h-[550px] overflow-scroll flex flex-col gap-y-4">
            {locations.map((location) => {
              const active = selected === location.id || (!selected && isHq(location))

              return (
                <button
                  type="button"
                  onClick={() => {
                    if (active && location.link) {
                      navigate(location.link)
                    } else {
                      onMarkerClick(location)
                    }
                  }}
                  className={clsx(
                    'p-4 flex flex-col px-8 py-6 font-hh-heading text-left hover:bg-hh-silver group',
                    active ? 'bg-hh-gray text-white' : 'bg-white text-black'
                  )}
                  key={location?.id}
                >
                  <span className="mb-2 flex flex-col">
                    <span>{location.recap?.country}</span>
                  </span>

                  <strong className="xl:text-2xl sm:text-xl text-lg uppercase group-hover:underline">{location.title}</strong>

                  {active && (
                    <div className="w-full relative">
                      <hr className="my-4 bg-black border-black" />

                      <div className="flex items-center gap-x-4 xl:text-xl sm:text-lg text-base mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
                          <g clipPath="url(#clip0_457_4567)">
                            <path
                              fill="#fff"
                              d="M14.792 16.939A12.638 12.638 0 018.07 10.2l3.542-3.548L5.09.127 1.788 3.43A6.146 6.146 0 000 7.812C0 15.363 9.638 25 17.188 25a6.136 6.136 0 004.382-1.788l3.303-3.303-6.526-6.526-3.555 3.556zm5.304 4.8a4.062 4.062 0 01-2.908 1.178c-6.493 0-15.104-8.612-15.104-15.105A4.07 4.07 0 013.26 4.904l1.83-1.83 3.58 3.58L5.614 9.71l.255.64a14.972 14.972 0 008.799 8.787l.631.241 3.048-3.049 3.58 3.58-1.831 1.83zM14.584 2.084V0A10.428 10.428 0 0125 10.417h-2.083a8.343 8.343 0 00-8.334-8.334zm0 4.167V4.167a6.256 6.256 0 016.25 6.25H18.75a4.167 4.167 0 00-4.166-4.167z"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_457_4567">
                              <path fill="#fff" d="M0 0H25V25H0z"></path>
                            </clipPath>
                          </defs>
                        </svg>

                        <span>{location.recap?.phone}</span>
                      </div>

                      <div className="flex items-center gap-x-4 xl:text-xl sm:text-lg text-base">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
                          <g clipPath="url(#clip0_457_4566)">
                            <path
                              fill="#fff"
                              d="M21.875 1.042H3.125A3.125 3.125 0 000 4.167v19.792h25V4.167a3.125 3.125 0 00-3.125-3.125zM3.125 3.125h18.75a1.042 1.042 0 011.042 1.042v.695l-8.207 8.207a3.132 3.132 0 01-4.42 0L2.083 4.862v-.695a1.042 1.042 0 011.042-1.042zm-1.042 18.75V7.813l6.734 6.729a5.215 5.215 0 007.366 0l6.734-6.73v14.063H2.083z"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_457_4566">
                              <path fill="#fff" d="M0 0H25V25H0z"></path>
                            </clipPath>
                          </defs>
                        </svg>

                        <span>{location.recap?.email}</span>
                      </div>

                      <div className="flex items-center justify-end mt-4">
                        <span className="mr-4 xl:text-lg sm:text-base text-sm leading-none mb-1 text-white">{props.readMore}</span>
                        <svg
                          className="w-[12px] sm:h-[22px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="22"
                          fill="none"
                          viewBox="0 0 6 11"
                        >
                          <path
                            fill="#323232"
                            d="M6 5.5a1.63 1.63 0 01-.481 1.16L1.163 11 0 9.84 4.355 5.5 0 1.16 1.163 0l4.355 4.34A1.634 1.634 0 016 5.5z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  )}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
