import type { Knowledge, Page_Flexcontent_Flex_PdfViewer } from '~/graphql/types'
import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import Reader from '~/components/elements/pdf/Reader.client'
import { ClientOnly } from '~/components/elements/ClientOnly'

export default function PdfViewer({ fields }: { fields: Page_Flexcontent_Flex_PdfViewer }) {
  const { page } = useLoaderData<LoaderData<Knowledge>>()

  if (!page.recap?.pdf || !page.recap?.pdf.mediaItemUrl) {
    return null
  }

  return (
    <section id="PdfViewer" className="bg-hh-mine-shaft h-fit">
      <div className="container xl:max-w-screen-xl section">
        <ClientOnly>{() => <Reader file={page.recap?.pdf?.mediaItemUrl} />}</ClientOnly>
      </div>
    </section>
  )
}
