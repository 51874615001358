import { Form, useLoaderData, useSearchParams, useSubmit } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import Globe, { type Marker } from '~/components/elements/locations/Globe.client'
import type { Location, Page, Page_Flexcontent_Flex_Locations } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import Content from '../Content'
import { isHq, mapToMarkers } from './Locations'
import GLErrorBoundary from '~/components/GLErrorBoundary'
import { ClientOnly } from '../ClientOnly'

interface RegionProps {
  title: string
  locations: Location[]
  onSearchButtonClick: () => void
  selectionLabel: Page_Flexcontent_Flex_Locations['selectionLabel']
  findLocationLabel: Page_Flexcontent_Flex_Locations['findLocationLabel']
  readMore: Page_Flexcontent_Flex_Locations['readMore']
}

interface Params {
  selected?: string
  region?: string
}

export default function Region({ title, locations, onSearchButtonClick, selectionLabel, findLocationLabel, readMore }: RegionProps) {
  const hq = locations.find((location) => isHq(location))
  const data = useLoaderData<LoaderData<Page>>()
  const [searchParams] = useSearchParams()
  const [params, setParams] = useState<Params>({
    selected: searchParams.get('selected') || hq?.id || undefined,
    region: searchParams.get('region') || undefined
  })
  const submit = useSubmit()
  const formRef = useRef<HTMLFormElement>(null)
  const init = useRef(false)

  const markers = mapToMarkers(locations, searchParams)
  const regions = [...new Set(locations.map((it) => it.recap?.region))].filter((it) => it) as string[]
  const location = locations.find((it) => it.id === searchParams.get('selected')) || hq

  function onMarkerClick(marker: Marker | Location) {
    setParams({
      ...params,
      selected: params.selected === marker.id ? undefined : marker.id
    })
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, region: string) => {
    e.preventDefault()

    setParams({
      ...params,
      region
    })
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true

      return
    }

    if (formRef.current) {
      submit(formRef.current)
    }
  }, [params, submit])

  return (
    <section className="section bg-hh-mine-shaft text-white">
      <div className="container">
        <Content className="content-lg lg:mb-0 mb-10">{title}</Content>
      </div>

      <div className="container max-w-screen-xl mx-auto grid grid-cols-12">
        <div className="lg:col-span-3 sm:col-span-6 col-span-12 order-1 flex flex-col justify-center lg:pl-0 sm:pl-20 sm:mb-0 mb-10">
          {location && (
            <>
              <div className="flex items-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3" width="24" height="25" fill="none" viewBox="0 0 24 25">
                  <g fill="#00A1FF" clipPath="url(#clip0_806_5103)">
                    <path d="M12 .976A10.042 10.042 0 004.932 3.89 9.92 9.92 0 002 10.915c0 5.25 8.4 12.457 9.354 13.262l.646.543.646-.543C13.6 23.372 22 16.165 22 10.915a9.92 9.92 0 00-2.932-7.025A10.042 10.042 0 0012 .976zm0 14.909a5.022 5.022 0 01-2.778-.838 4.977 4.977 0 01-1.841-2.23 4.942 4.942 0 011.083-5.416 5.028 5.028 0 015.449-1.077 4.994 4.994 0 012.244 1.83c.55.817.843 1.778.843 2.761a4.96 4.96 0 01-1.466 3.513A5.022 5.022 0 0112 15.885z"></path>
                    <path d="M12 13.897c1.657 0 3-1.335 3-2.982a2.99 2.99 0 00-3-2.981 2.99 2.99 0 00-3 2.981 2.99 2.99 0 003 2.982z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_806_5103">
                      <path fill="#fff" d="M0 0H24V23.855H0z" transform="translate(0 .976)"></path>
                    </clipPath>
                  </defs>
                </svg>

                <span className="font-light text-white/75">{selectionLabel}</span>
              </div>
              <hr className="mb-4 lg:block hidden" />
              <div>
                <strong className="uppercase font-hh-heading text-2xl mb-4 block">{location.title}</strong>
                {location.recap && (
                  <div className="flex flex-col text-white font-hh-heading">
                    <span>{location.recap.address}</span>
                    <span>{location.recap.city}</span>
                    <span>{location.recap.country}</span>
                    {location.link && (
                      <LosseLink className="mt-4 button-blue self-start text-sm" to={location.link}>
                        {readMore}
                      </LosseLink>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="lg:col-span-6 col-span-12 flex flex-col items-center justify-center lg:order-2 order-3">
          <div className="xl:min-h-[600px] lg:min-h-[500px] min-h-[400px]">
            <ClientOnly>
              {() => (
                <GLErrorBoundary hasError={false}>
                  <Globe onClick={onMarkerClick} markers={markers} />
                </GLErrorBoundary>
              )}
            </ClientOnly>
          </div>

          <button className="button-blue -mt-6 relative z-10" onClick={onSearchButtonClick} type="button">
            {findLocationLabel}
          </button>
        </div>
        <Form
          ref={formRef}
          action={new URL(data.request.url).pathname}
          method="get"
          className="lg:col-span-3 sm:col-span-6 col-span-12 text-center sm:text-left sm:flex flex-col items-center justify-center gap-y-6 order-3 mt-4 sm:mt-0 sm:order-2 lg:order-3"
        >
          <input type="hidden" name="selected" value={params.selected} />
          <input type="hidden" name="region" value={params.region} />

          {regions.map((region) => {
            return (
              <button
                type="button"
                className={clsx(
                  'button-white-opaque lg:min-w-[200px] sm:mr-0 mr-3 sm:mt-0 mt-3',
                  searchParams.get('region') === region ? 'border-hh-blue text-hh-blue' : 'border-white text-white'
                )}
                onClick={(e) => handleClick(e, region)}
                key={region}
              >
                {regionCodeToName(region)}
              </button>
            )
          })}
        </Form>
      </div>
    </section>
  )
}

function regionCodeToName(region: string) {
  switch (region) {
    case 'afr':
      return 'Africa'
    case 'asia':
      return 'Asia'
    case 'eu':
      return 'Europe'
    case 'na':
      return 'North America'
    case 'sa':
      return 'South America'
    case 'me':
      return 'Middle East'
    case 'oce':
      return 'Oceania'
    default:
      return region
  }
}
