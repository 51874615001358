import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Links } from '~/graphql/types'

export default function LinksWithIconDefault({ fields }: { fields: Page_Flexcontent_Flex_Links }) {
  return (
    <section id='LinksWithIconHorizontally' className="section">
      <div className="container max-w-[1100px]">
        {fields.title && fields.enableTitle && (
          <Content className="content-lg text-center lg:mb-16 mb-10 -mt-1">{`<h2>${fields.title}</h2>`}</Content>
        )}
        <div className="grid lg:grid-cols-3 grid-cols-2 md:gap-x-10 sm:gap-x-6 gap-x-4 md:gap-y-14 sm:gap-y-10 gap-y-6 mb-4">
          {fields.items?.map((item, index) => (
            <div key={index} className="col-span-1 flex items-center">
              <div className="md:pl-14 sm:pl-10 pl-6 w-full">
                <LosseLink
                  to={item?.link?.url}
                  target={item?.link?.target as string}
                  className="block group hover:bg-hh-blue transition-colors relative xl:pl-20 sm:pl-16 pl-10 xl:pr-10 sm:pr-8 pr-6 lg:py-7 md:py-5 sm:py-4 py-2 rounded-[100px] shadow-hh-outer w-full focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-hh-blue"
                >
                  <span className="absolute transition-colors left-0 -translate-x-1/2 top-1/2 -translate-y-1/2 md:w-24 md:h-24 sm:h-16 sm:w-16 h-8 w-8 bg-white border-[1px] border-solid border-hh-gray rounded-full flex items-center justify-center ring-4 ring-offset-4 ring-offset-white ring-hh-gray group-hover:ring-hh-blue group-hover:border-hh-blue">
                    <LossePlaatjie
                      maxwidth={100}
                      src={item?.icon}
                      className="object-contain sm:w-7 sm:h-7 h-3 w-3 filter-gray group-hover:filter-blue"
                    />
                  </span>
                  <Content className="font-hh-heading sm:text-base text-xs font-bold uppercase text-hh-mine-shaft group-hover:text-white block text-center">
                    {item?.link?.title}
                  </Content>
                </LosseLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
