import { LosseLink } from '@ubo/losse-sjedel'
import Popup from './Popup'

export default function NewsLetter() {
  return (
    <>
      <LosseLink
        to="?newsletter=true"
        className="fixed z-[35] right-0 top-[40%] -rotate-90 origin-bottom-right lg:block hidden bg-hh-mine-shaft text-white shadow-box title uppercase px-5 xl:px-7 py-2 xl:py-4 button-base-ring hover:bg-hh-blue focus:ring-hh-blue"
        state={{
          scroll: false
        }}
      >
        Subscribe to our newsletter
      </LosseLink>

      <Popup name="newsletter" componentId={1097} />
    </>
  )
}
