import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import { LosseLink, useLosseData } from '@ubo/losse-sjedel'

import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import type { Exhibition, Page_Flexcontent_Flex_Exhibitions } from '~/graphql/types'
import Message from '~/components/elements/Message'

export default function ExhibitionsDefault({ fields }: { fields: Page_Flexcontent_Flex_Exhibitions }) {
  const [valueRange, onChangeRange] = useState<[Date] | [Date, Date] | null | []>(null)
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date())
  const [data, state, revalidate] = useLosseData<{ edges: { node: Exhibition }[] }>(
    'Exhibitions',
    {
      dateStartYear: activeStartDate.getFullYear(),
      dateStartMonth: activeStartDate.getMonth() + 1
    },
    true
  )
  const ref = useRef<HTMLDivElement>(null)

  function dateToDay(start: string, end: string) {
    const startDate = dayjs(start, 'DD/MM/YYYY')
    const endDate = dayjs(end, 'DD/MM/YYYY')

    if (start === end) {
      return startDate.format('DD')
    }

    return `${startDate.format('DD')} - ${endDate.format('DD')}`
  }

  function dateToMonth(start: string, end: string) {
    const startDate = dayjs(start, 'DD/MM/YYYY')
    const endDate = dayjs(end, 'DD/MM/YYYY')

    if (startDate.format('MMMM') === endDate.format('MMMM')) {
      return startDate.format('MMMM')
    }

    return `${startDate.format('MMMM')} - ${endDate.format('MMMM')}`
  }

  useEffect(() => {
    if (data) {
      revalidate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStartDate.getMonth(), activeStartDate.getFullYear()])

  const selectedExhibitions = data?.edges.filter(({ node }) => {
    if (valueRange && valueRange[0] && valueRange[1]) {
      const [start, end] = valueRange

      const startDate = dayjs(node.recap?.dateStart, 'DD/MM/YYYY')
      const endDate = dayjs(node.recap?.dateEnd, 'DD/MM/YYYY')

      return startDate.isBetween(start, end, 'day', '[]') && endDate.isBetween(start, end, 'day', '[]')
    }
    return false
  })

  return (
    <section className={clsx('section', fields.backgroundColor === 'concrete' && 'bg-hh-concrete')}>
      <div className="container xl:max-w-screen-xl">
        <div className="grid md:grid-cols-2 items-start xl:gap-16 lg:gap-8 gap-8">
          <div
            className={clsx('col-span-1 flex items-center py-10 sm:px-6 px-1', fields.backgroundColor === 'white' && 'bg-hh-blue/[.15]')}
          >
            <div className="w-full">
              <div className="flex justify-center w-full">
                <Calendar
                  onClickDay={(day) => {
                    const exhibition = data?.edges.find(({ node }) => {
                      const dateStart = dayjs(node.recap?.dateStart, 'DD/MM/YYYY')
                      const dateEnd = dayjs(node.recap?.dateEnd, 'DD/MM/YYYY')

                      return (dayjs(day).isAfter(dateStart) && dayjs(day).isBefore(dateEnd)) || dateStart.isSame(day) || dateEnd.isSame(day)
                    })

                    if (!exhibition) {
                      onChangeRange([])
                      return
                    }

                    onChangeRange([
                      dayjs(exhibition?.node.recap?.dateStart, 'DD/MM/YYYY').toDate(),
                      dayjs(exhibition?.node.recap?.dateEnd, 'DD/MM/YYYY').toDate()
                    ])

                    if (window.innerWidth < 640 && ref.current) {
                      window.scrollTo({
                        top: ref.current?.offsetTop - 200,
                        behavior: 'smooth'
                      })
                    }
                  }}
                  locale={'en-GB'}
                  activeStartDate={activeStartDate}
                  onActiveStartDateChange={(date) => {
                    if (date.action === 'drillUp') {
                      return
                    }

                    setActiveStartDate(date.activeStartDate)
                  }}
                  tileClassName={({ date }) => {
                    const isStartDateActive = ({ node }: { node: Exhibition }) => {
                      const dateStart = dayjs(node.recap?.dateStart, 'DD/MM/YYYY')
                      return dayjs(date).isSame(dateStart)
                    }

                    const isEndDateActive = ({ node }: { node: Exhibition }) => {
                      const dateEnd = dayjs(node.recap?.dateEnd, 'DD/MM/YYYY')
                      return dayjs(date).isSame(dateEnd)
                    }

                    const isActive = ({ node }: { node: Exhibition }) => {
                      const dateStart = dayjs(node.recap?.dateStart, 'DD/MM/YYYY')
                      const dateEnd = dayjs(node.recap?.dateEnd, 'DD/MM/YYYY')
                      return (
                        (dayjs(date).isAfter(dateStart) && dayjs(date).isBefore(dateEnd)) ||
                        dayjs(date).isSame(dateStart) ||
                        dayjs(date).isSame(dateEnd)
                      )
                    }
                    const classes = ['sm:text-base text-sm']
                    if (data?.edges.find(isStartDateActive)) {
                      classes.push('is-start')
                    }

                    if (data?.edges.find(isEndDateActive)) {
                      classes.push('is-end')
                    }

                    if (valueRange) {
                      const isSame = valueRange.find((_date) => {
                        return dayjs(date).isSame(_date)
                      })

                      if (isSame) classes.push('is-selected')
                      if (dayjs(date).isBetween(valueRange[0], valueRange[1])) {
                        classes.push('is-selected')
                      }
                    }

                    const isTileActive = data?.edges.find(isActive)
                    isTileActive
                      ? classes.push(
                          fields.backgroundColor === 'concrete' ? 'bg-[#DBDBDB] text-black is-active' : 'bg-white text-black is-active'
                        )
                      : classes.push('bg-hh-transparent')

                    return classes.join(' ')
                  }}
                  nextLabel={
                    <div className="ml-10">
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
                          fill="#323232"
                        />
                      </svg>
                    </div>
                  }
                  prevLabel={
                    <div className="mr-10">
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.66694e-06 5.5C-0.000374548 5.28465 0.041951 5.07135 0.124546 4.87236C0.207141 4.67336 0.328377 4.4926 0.481285 4.34046L4.83669 -5.08498e-08L6 1.15953L1.64541 5.5L6 9.84046L4.83669 11L0.482108 6.65953C0.329051 6.50746 0.207664 6.32673 0.124926 6.12774C0.0421889 5.92874 -0.000266325 5.71541 1.66694e-06 5.5Z"
                          fill="#323232"
                        />
                      </svg>
                    </div>
                  }
                  next2Label={<div />}
                  prev2Label={<div />}
                />
              </div>

              {fields.link && (
                <div className="mt-12 flex justify-center">
                  <LosseLink src={fields.link} className="button-mine-shaft-opaque" />
                </div>
              )}
            </div>
          </div>
          <div className="col-span-1 flex items-start">
            <div className="w-full" ref={ref}>
              {fields.description && <Content className="content-lg mb-12">{fields.description}</Content>}

              {state === 'loading' && (
                <div className="py-10 flex justify-center">
                  <Loading />
                </div>
              )}
              {state === 'done' && (
                <div className="flex flex-col gap-y-7">
                  {data?.edges.length === 0 && <Message>No exhibitions scheduled for this month</Message>}
                  {data?.edges
                    .sort((a, b) => {
                      const aDate = dayjs(a.node.recap?.dateStart, 'DD/MM/YYYY')
                      const bDate = dayjs(b.node.recap?.dateStart, 'DD/MM/YYYY')

                      // @ts-ignore
                      return aDate.toDate() - bDate.toDate()
                    })
                    .map((edge) => {
                      const isActive = selectedExhibitions?.find(({ node }) => {
                        return node.id === edge.node.id
                      })

                      return (
                        <LosseLink
                          to={edge.node.uri}
                          key={edge.node.uri}
                          className={clsx(
                            'xl:px-12 px-8 py-5 box-shadow relative group button-base-ring focus:ring-offset-white focus:ring-hh-blue group',
                            isActive ? 'bg-hh-mine-shaft text-white' : 'bg-white text-hh-mine-shaft'
                          )}
                        >
                          <div className="grid grid-cols-12 xl:gap-x-12">
                            <div className="xl:col-span-5 col-span-4">
                              {edge.node.recap && edge.node.recap.dateStart && edge.node.recap.dateEnd && (
                                <div className="title xl:text-4xl lg:text-2xl text-lg group-hover:underline">
                                  {dateToDay(edge.node.recap.dateStart, edge.node.recap.dateEnd)}
                                </div>
                              )}
                            </div>
                            <div className="xl:col-span-7 col-span-8 flex items-center">
                              <h2 className="title xl:text-2xl lg:text-xl text-lg uppercase group-hover:underline">{edge.node.title}</h2>
                            </div>

                            <div className="xl:col-span-5 col-span-4">
                              {edge.node.recap && edge.node.recap.dateStart && edge.node.recap.dateEnd && (
                                <div className="font-hh-heading xl:text-lg text-base">
                                  {dateToMonth(edge.node.recap.dateStart, edge.node.recap.dateEnd)}
                                </div>
                              )}
                            </div>
                            <div className="xl:col-span-7 col-span-8">
                              <h3 className="title lg:text-base textxs uppercase">{edge.node.recap?.location || ''}</h3>
                            </div>
                          </div>

                          <img
                            src="/icons/chevron-right-black.svg"
                            className={clsx(
                              'w-4 h-4 object-contain absolute right-5 top-0 bottom-0 transition-all m-auto group-hover:right-4',
                              isActive && 'invert'
                            )}
                            aria-label="Chevron Right"
                          />
                        </LosseLink>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
