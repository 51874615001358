/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useLoaderData } from '@ubo/losse-sjedel'
import { AnimatePresence, motion } from 'framer-motion'
import { LosseLink } from '@ubo/losse-sjedel'
import useMediaQuery from '~/hooks/useMediaQuery'
import type { HeaderLoaderData } from '../Header'

const Menu: React.FC<{
  currentSubmenu: number
  setSubmenu: (submenu: number) => void
}> = ({ currentSubmenu, setSubmenu }) => {
  const { header } = useLoaderData<HeaderLoaderData>()
  const isMobile = useMediaQuery('(max-width: 1024px)')

  return (
    <div className="flex flex-col gap-4 lg:gap-16">
      <div className="lg:hidden relative flex">
        <LosseLink
          className={`text-lg lg:text-3xl uppercase font-hh-heading font-light lg:font-bold transition-colors duration-200 text-white`}
          to={header.information?.quicksupport?.url || '/'}
        >
          {header.information?.quicksupport?.title}
        </LosseLink>
      </div>
      {header?.menuHeader?.map((l, index: number) => (
        <motion.div
          animate={currentSubmenu === index && !isMobile ? { paddingLeft: 45 } : { paddingLeft: 0 }}
          className="relative flex"
          key={index}
        >
          <AnimatePresence>
            {currentSubmenu === index && (
              <motion.svg
                initial={{ opacity: 0, x: -45 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -45 }}
                className="hidden lg:block absolute top-0 -bottom-1 my-auto left-0"
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 10C12.0007 10.3915 11.9161 10.7794 11.7509 11.1412C11.5857 11.503 11.3432 11.8316 11.0374 12.1082L2.32661 20L-1.07969e-07 17.8918L8.70917 10L-9.16285e-07 2.10825L2.32661 -8.68032e-08L11.0358 7.89176C11.3419 8.16825 11.5847 8.49685 11.7501 8.85866C11.9156 9.22047 12.0005 9.60835 12 10Z"
                  fill="#00A1FF"
                />
              </motion.svg>
            )}
          </AnimatePresence>
          <motion.div
            onTap={() => (l?.submenu?.length || 0) > 0 && setSubmenu(index)}
            onHoverStart={() => (l?.submenu?.length || 0) > 0 && setSubmenu(index)}
          >
            <LosseLink
              className={`text-lg lg:text-3xl uppercase font-hh-heading font-light lg:font-bold transition-colors duration-200 ${
                currentSubmenu === index ? `text-hh-blue` : `text-white`
              }`}
              to={l?.link?.url || '/'}
            >
              {l?.link?.title}
            </LosseLink>
            {(l?.submenu?.length || 0) > 0 && (
              <svg
                className="lg:hidden block absolute top-0 -bottom-1 my-auto right-0"
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 5.66658C6.00037 5.88846 5.95805 6.10823 5.87545 6.31325C5.79286 6.51827 5.67162 6.70451 5.51871 6.86126L1.16331 11.3333L-1.80291e-06 10.1386L4.35458 5.66658L-5.44162e-07 1.19459L1.16331 -8.24388e-05L5.51789 4.47191C5.67095 4.62859 5.79233 4.8148 5.87507 5.01982C5.95781 5.22485 6.00027 5.44465 6 5.66658Z"
                  fill={currentSubmenu === index ? `#00A1FF` : `#ffffff`}
                />
              </svg>
            )}
          </motion.div>
        </motion.div>
      ))}
    </div>
  )
}

export default Menu
