import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { useRef } from 'react'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import type { LoaderData } from '@ubo/losse-sjedel'
import SocialSharing from '~/components/elements/SocialSharing'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import clsx from 'clsx'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const element = useRef<HTMLDivElement>(null)
  const { page } = useLoaderData<LoaderData>()
  const isPost = page?.contentType?.node?.name === 'post'

  return (
    <section className="2xl:pt-10">
      <div
        className="container max-w-screen-2xl relative sm:h-[500px] md:h-[600px] xl:h-[750px] sm:flex items-end p-5 sm:p-8 md:py-14 xl:py-28 md:px-14"
        ref={element}
      >
        <div className="sm:absolute inset-0 z-0 h-[275px] sm:h-auto">
          <LossePlaatjie maxwidth={2000} className="w-full h-full object-cover" src={fields.image} />
        </div>
        <div>
          <div
            className={clsx(
              'relative z-10 sm:min-w-[450px] w-full sm:w-auto inline-block bg-black/50 p-5 sm:p-8 md:px-16 md:py-10',
              isPost ? 'max-w-3xl' : 'max-w-xl'
            )}
          >
            <Content className="content text-white">{fields?.description}</Content>

            <div className="mt-5 md:mt-10 flex items-center">
              <Breadcrumbs title={page?.title || ''} inBanner />
            </div>
          </div>
          {fields.socialSharing && (
            <div className="flex mt-2 sm:mt-0">
              <div className="relative z-10 bg-white py-1 sm:py-3 sm:px-5 flex items-center">
                <div className="mr-5 title uppercase text-sm sm:text-m">Share this:</div>
                <SocialSharing className="flex items-center" />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center pb-2 lg:pb-10">
        <button
          type="button"
          onClick={() => {
            if (!element.current) {
              return
            }

            const top = element.current.clientHeight

            if (top - window.scrollY <= 0) {
              return
            }

            window.scrollTo({
              top,
              left: 0,
              behavior: 'smooth'
            })
          }}
          className="w-10 h-10 text-[0px] flex items-center justify-center"
        >
          <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 12C10.5693 12.0007 10.1427 11.9161 9.74471 11.7509C9.34673 11.5857 8.98521 11.3432 8.68093 11.0374L0 2.32661L2.31907 0L11 8.70917L19.6809 0L22 2.32661L13.3191 11.0358C13.0149 11.3419 12.6535 11.5847 12.2555 11.7501C11.8575 11.9156 11.4308 12.0005 11 12Z"
              fill="#323232"
              fillOpacity="0.5"
            />
          </svg>
          Scroll down
        </button>
      </div>
    </section>
  )
}
