import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Overview, Vacancy } from '~/graphql/types'
import type { Edge } from '../OverviewDefault'

export default function VacancyItem({ edge, fields }: { edge: Edge<Vacancy>; fields: Page_Flexcontent_Flex_Overview }) {
  return (
    <div className="sm:col-span-1 col-span-2 bg-white">
      <div className="lg:p-6 p-4">
        <div className="flex items-center overflow-x-auto no-scrollbar lg:-mr-6 -mr-4">
          <span className="tag flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 w-3 h-3"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            {edge.node.recap?.enrollmentStatus}
          </span>
          <span className="tag flex items-center ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 w-3 h-3"
            >
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
              <circle cx="12" cy="10" r="3"></circle>
            </svg>
            {edge.node.recap?.location}
          </span>
        </div>
        <h2 className="title text-xl lg:text-2xl mt-3">{edge.node?.title}</h2>
        <Content className="content mt-2 line-clamp-3 lg:text-lg font-light">{edge.node.recap?.intro}</Content>
        <div className="mt-6 flex justify-end">
          <LosseLink to={edge.node.uri} className="button-blue">
            {fields.readMoreButton}
          </LosseLink>
        </div>
      </div>
    </div>
  )
}
