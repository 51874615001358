import React from 'react'
import { motion } from 'framer-motion'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Slider_Posts } from '~/graphql/types'

export default function MarketItem({ post }: { post: Page_Flexcontent_Flex_Slider_Posts }) {
  return (
    <motion.div whileHover="hover">
      <LosseLink
        className="bg-hh-silver w-full flex items-end justify-start relative h-[350px] sm:h-[450px] lg:h-[500px] overflow-hidden"
        to={post?.uri}
      >
        <div className="absolute inset-0 z-0">
          <LossePlaatjie src={post?.recap?.image} maxwidth={600} className="w-full h-full object-cover" />
        </div>
        <motion.div
          initial={{
            opacity: 1
          }}
          variants={{
            hover: {
              opacity: 0
            }
          }}
          transition={{
            duration: 0.2
          }}
          className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black"
        />
        <motion.div
          initial={{
            opacity: 0,
            scale: 1.1
          }}
          variants={{
            hover: {
              opacity: 1,
              scale: 1
            }
          }}
          transition={{
            duration: 0.2
          }}
          className="absolute inset-0 z-10 flex items-center justify-center"
        >
          <div className="absolute inset-0 z-0 bg-black opacity-50" />
          {(post?.__typename === 'Post' || post?.__typename === 'Market') && (
            <LossePlaatjie maxwidth={600} src={post?.recap?.icon} className="relative z-10 h-36 w-36 object-contain" />
          )}
        </motion.div>
        <div className="relative z-20 px-5 sm:px-7 py-7 sm:py-10 text-white">
          <h2 className="title uppercase text-2xl lg:text-2xl">{post?.title}</h2>
          <motion.h3
            initial={{ opacity: 0 }}
            variants={{
              hover: {
                opacity: 0.8
              }
            }}
            className="paragraph mt-2"
          >
            Read more
          </motion.h3>
        </div>
      </LosseLink>
    </motion.div>
  )
}
