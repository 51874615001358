/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLoaderData, useLocation } from '@ubo/losse-sjedel'
import HeaderMenu from './MegaMenu'
import { useLosseLayout, LosseLink } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import MegaSubmenu from './MegaSubmenu'
import MegaInformation from './MegaInformation'
import HeaderDynamic from '../Dynamic/HeaderDynamic'
import HeaderSearch from '../HeaderSearch'
import type { HeaderLoaderData } from '../Header'
import LanguageSwitch from '~/components/elements/LanguageSwitch'
import clsx from 'clsx'
import useMediaQuery from '~/hooks/useMediaQuery'

const HeaderMega: React.FC<{
  className?: string
}> = ({ className = '' }) => {
  const { setScrollable, isScrollable } = useLosseLayout()
  const location = useLocation()
  const { header } = useLoaderData<HeaderLoaderData>()
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const [currentSubmenu, setSubmenu] = useState(isMobile ? -1 : 0)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  useEffect(() => {
    if (isMobile && currentSubmenu !== -1) {
      setSubmenu(-1)
    } else if (!isMobile && currentSubmenu === -1) {
      setSubmenu(0)
    }
  }, [isMobile])

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  }, [location.pathname])

  function handleKeydown(event: KeyboardEvent) {
    if (isOpen && event.key === 'Escape') {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    if (isOpen && isScrollable) {
      setScrollable(false)
    } else if (!isOpen && !isScrollable) {
      setScrollable(true)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen])

  return (
    <div className={clsx(className, 'flex justify-between items-center gap-2 sm:gap-5 md:gap-9')}>
      {header?.information?.quicksupport && (
        <LosseLink
          className="button-blue lg:block hidden text-sm py-2 font-hh-form font-light normal-case"
          to={header?.information.quicksupport.url}
        >
          {header?.information.quicksupport.title}
        </LosseLink>
      )}
      <LanguageSwitch className="ml-4" languages={header?.information?.languages} />
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        className="z-10 gap-2 h-10 button-transparent min-w-[unset] flex items-center text-center"
        aria-label="Open menu"
      >
        <div>
          <div className="mx-auto my-[5px] h-[2px] w-[24px] bg-white" />
          <div className="mx-auto my-[5px] h-[2px] w-[24px] bg-white" />
          <div className="mx-auto my-[5px] h-[2px] w-[24px] bg-white" />
        </div>
        <div className="text-white text-sm font-light normal-case font-hh-form sm:block hidden">Menu</div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.3 }}
            className="fixed inset-x-0 top-0 z-[60] h-screen bg-white"
          >
            <div className="containerize flex h-full w-full bg-[#EAEAEA]">
              <div
                role="button"
                aria-label="Back"
                onClick={() => {
                  setSubmenu(-1)
                }}
                className={currentSubmenu === -1 ? `hidden` : `block lg:hidden absolute z-[40] lg:z-30 md:left-10 left-5 top-11`}
              >
                <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.93317e-06 8.5C-0.000560372 8.16719 0.0629276 7.83754 0.18682 7.53C0.310713 7.22247 0.492566 6.94311 0.721929 6.70799L7.25504 -7.62748e-08L9 1.79201L2.46812 8.5L9 15.208L7.25504 17L0.723163 10.292C0.493578 10.057 0.311497 9.77768 0.187391 9.47014C0.0632847 9.1626 -0.000398578 8.8329 3.93317e-06 8.5Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div
                role="button"
                onClick={() => {
                  setSubmenu(-1)
                }}
                aria-label="Category"
                className={
                  currentSubmenu === -1
                    ? `hidden`
                    : `block lg:hidden absolute z-[40] lg:z-30 text-center right-0 left-0 mx-auto top-9 text-lg font-bold uppercase font-hh-heading text-[#888888]`
                }
              >
                {header.menuHeader![currentSubmenu]?.link?.title}
              </div>

              <div
                className="absolute z-[40] lg:z-30 right-5 md:right-10 top-10 lg:top-5 text-center"
                role="button"
                onClick={() => {
                  setSubmenu(-1)
                  setIsOpen(!isOpen)
                }}
                aria-label="Close menu"
              >
                <svg className="lg:hidden block" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 1.17833L18.8217 0L10 8.82167L1.17833 0L0 1.17833L8.82167 10L0 18.8217L1.17833 20L10 11.1783L18.8217 20L20 18.8217L11.1783 10L20 1.17833Z"
                    fill="white"
                  />
                </svg>
                <div className="relative hidden lg:block ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[4px] before:rotate-45 before:bg-hh-blue before:[content:''] after:absolute after:h-[33px] after:w-[4px] after:-rotate-45 after:bg-hh-blue after:[content:'']" />
              </div>
              <div className="bg-hh-mine-shaft w-full lg:block flex flex-col justify-between lg:w-1/3 pt-20 pb-10 px-5 md:px-10 lg:px-20 overflow-y-auto">
                <div className="lg:hidden block flex-grow">
                  <div className="flex flex-wrap items-center justify-between w-full mb-8 max-sm:gap-4">
                    <HeaderSearch />
                    <LanguageSwitch response={false} languages={header.information?.languages} />
                  </div>
                  <HeaderDynamic />
                </div>
                <div className="py-10 lg:py-0">
                  <HeaderMenu currentSubmenu={currentSubmenu} setSubmenu={setSubmenu} />
                </div>
              </div>
              <div className="bg-white hidden lg:block w-1/3 pt-20 pb-10 pl-20 overflow-y-auto">
                {header.menuHeader && header.menuHeader[currentSubmenu] && (
                  <MegaSubmenu currentSubmenu={currentSubmenu} fields={header.menuHeader[currentSubmenu]?.submenu || []} />
                )}
              </div>
              <AnimatePresence>
                {header.menuHeader && header.menuHeader[currentSubmenu] && (
                  <motion.div
                    initial={{ x: '-100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{ duration: 0.5 }}
                    className="bg-hh-mine-shaft lg:hidden h-full w-full pt-32 pb-10 px-5 md:px-10 lg:px-20 overflow-y-auto absolute top-0 left-0"
                  >
                    <MegaSubmenu currentSubmenu={currentSubmenu} fields={header.menuHeader[currentSubmenu]?.submenu || []} />
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="bg-[#EAEAEA] hidden lg:flex flex-col justify-between py-20 w-1/3 pl-20">
                <div className="hidden lg:block">
                  <div className="title text-2xl uppercase">Go straight to:</div>
                  <div className="mt-4">
                    {header.information?.menu?.map((item) => (
                      <LosseLink
                        key={item?.link?.url}
                        src={item?.link}
                        className="text-lg font-light normal-case font-hh-form block hover:underline hover:text-hh-blue transition-colors mb-4"
                      />
                    ))}
                  </div>
                </div>
                <MegaInformation fields={header.information || {}} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default HeaderMega
