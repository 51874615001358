import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Knowledge } from '~/graphql/types'
import { LosseLink, LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { knowledgeColor } from '../Overview/default-item-types/KnowledgeItem'

export default function KnowledgeFeatured({ fields }: { fields: Page_Flexcontent_Flex_Knowledge }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <section className="bg-hh-concrete section" id="featured">
      <div className="container xl:max-w-screen-xl">
        {fields.description && <Content className="content mb-14">{fields.description}</Content>}

        <div className="grid grid-cols-1 gap-7 lg:gap-14">
          {fields.items?.map((item, index) => (
            <div key={index} className={clsx('grid grid-cols-10', knowledgeColor(item?.categories))}>
              <div className="col-span-3 relative flex items-center justify-center bg-hh-salem/20">
                <LossePlaatjie
                  src={item?.recap?.image}
                  maxwidth={350}
                  className="block w-[70%] h-[70%] sm:w-[80%] sm:h-[80%] max-h-[250px] object-contain mx-auto knowledge-image"
                />
              </div>
              <div className="col-span-7 bg-white pl-5 py-5 md:p-7  overflow-hidden">
                <div className="flex items-center mb-4 overflow-x-auto no-scrollbar h-[26px] -mr-3 md:-mr-5 xl:-mr-7">
                  {item?.categories?.nodes?.map((type, index) => (
                    <div key={index} className="tag mr-2">
                      {type?.name}
                    </div>
                  ))}
                </div>
                <h3 className="title text-xl lg:text-2xl mt-5">{item?.title}</h3>
                <Content className="content mt-5 font-hh-form line-clamp-3">{item?.recap?.description}</Content>
                <div className="mt-14 mr-2 lg:mr-0 flex justify-end">
                  {fields.readMoreText && (
                    <LosseLink to={item?.uri} className="button-mine-shaft-opaque">
                      {fields.readMoreText}
                    </LosseLink>
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      if (!item?.databaseId) {
                        return
                      }

                      searchParams.set('download-knowledge-featured', item?.databaseId.toString())
                      setSearchParams(searchParams, {
                        replace: true,
                        state: {
                          scroll: false
                        }
                      })
                    }}
                    className="button-blue ml-4"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
