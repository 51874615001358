import { useMatches } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import FormDuuf from '@ubo/form-duuf'
import FormLoading from './FormLoading'
import FormContent from './FormContent'

type fieldId = number

const pageTitleMappers: { [formId: number]: fieldId } = {
  11: 12, // Contact
  17: 1, // Product
  28: 1, // Exhibition
  14: 1, // Location
  12: 12, // Download,
  30: 10 // Vacancy (it)
}

interface FormProps extends Partial<FormDuufProps> {
  backgroundColor?: 'white' | 'concrete'
}

export default function Form(props: FormProps) {
  const matches = useMatches()
  const formId = props.data.formId
  const apiUrl = `${matches[0].data.config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`

  if (!props.data.formFields) {
    return <div />
  }

  const defaultFields: { [key: string]: string } = props.defaultFields || {}

  if (matches[1].pathname && pageTitleMappers[formId]) {
    defaultFields[`input_${pageTitleMappers[formId]}`] = matches[1]?.data?.page?.title
  }

  return (
    <FormDuuf
      defaultFields={defaultFields}
      privacyUrlPlacement="privacy policy"
      privacyUrl="/about-us/privacy-policy/"
      renderLoading={() => <FormLoading backgroundColor={props.backgroundColor || 'concrete'} />}
      renderStatus={({ content }) => <FormContent>{content}</FormContent>}
      className="relative"
      language="en"
      apiUrl={apiUrl}
      {...props}
    />
  )
}
