import type { ReactNode } from 'react'
import { Component } from 'react'

type Props = {
  hasError: boolean
  children: ReactNode
}
class GLErrorBoundary extends Component<Props> {
  state = {
    hasError: false
  }
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Props) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>
    }

    return this.props.children
  }
}

export default GLErrorBoundary
