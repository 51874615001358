import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import KnowledgeDefault from './KnowledgeDefault'
import KnowledgeBase from './KnowledgeBase'
import KnowledgeFeatured from './KnowledgeFeatured'

const KnowledgeShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: KnowledgeDefault,
    base: KnowledgeBase,
    featured: KnowledgeFeatured
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default KnowledgeShell
