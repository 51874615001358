import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Mousewheel } from 'swiper'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Content from '~/components/elements/Content'
import PostItem from './default-item-types/PostItem'
import ReferenceItem from './default-item-types/ReferenceItem'
import { LosseLink } from '@ubo/losse-sjedel'
import MarketItem from './default-item-types/MarketItem'
import clsx from 'clsx'

const getPostTypeName = (fields: Page_Flexcontent_Flex_Slider) => {
  const items = fields.posts
  const postTypeName = items?.[0]?.__typename

  return postTypeName || 'Post'
}

const breakpoints: any = {
  Post: {
    1536: {
      slidesPerView: 6,
      spaceBetween: 15
    },
    1280: {
      slidesPerView: 4.75,
      spaceBetween: 15
    },
    1024: {
      slidesPerView: 4.25,
      spaceBetween: 15
    },
    768: {
      slidesPerView: 3.75,
      spaceBetween: 15
    },
    576: {
      slidesPerView: 3.25,
      spaceBetween: 15
    },
    0: {
      slidesPerView: 1.75,
      spaceBetween: 15
    }
  },
  Reference: {
    1536: {
      slidesPerView: 6,
      spaceBetween: 15
    },
    1280: {
      slidesPerView: 4.5,
      spaceBetween: 15
    },
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 15
    },
    768: {
      slidesPerView: 2.75,
      spaceBetween: 15
    },
    640: {
      slidesPerView: 2.5,
      spaceBetween: 15
    },
    576: {
      slidesPerView: 2.25,
      spaceBetween: 15
    },
    0: {
      slidesPerView: 1.75,
      spaceBetween: 15
    }
  }
}

export default function SliderPosts({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const postTypeName = getPostTypeName(fields)

  if (!postTypeName) {
    return null
  }

  return (
    <section
      id="SliderPosts"
      className={clsx(
        fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
        fields.backgroundColor === 'mineshaft' && 'bg-hh-mine-shaft',
        fields.backgroundColor === 'white' && 'bg-white',
        fields.backgroundColor === 'pattensblue' && 'bg-hh-blue/20'
      )}
    >
      <div className="section">
        {fields.description && (
          <div className="container xl:max-w-screen-xl spacing-bottom">
            <Content className="content">{fields.description}</Content>
          </div>
        )}

        <div>
          {fields.startingPoint === 'left' && (
            <Slider
              fields={fields}
              settings={{
                breakpoints: breakpoints[postTypeName] || breakpoints.Post
              }}
            />
          )}
          {fields.startingPoint === 'container' && (
            <EndlessContainer className="xl:max-w-screen-xl">
              <Slider
                fields={fields}
                settings={{
                  breakpoints: {
                    1600: {
                      slidesPerView: 6,
                      spaceBetween: 15
                    },
                    1536: {
                      slidesPerView: 4.5,
                      spaceBetween: 15
                    },
                    1280: {
                      slidesPerView: 3.8,
                      spaceBetween: 15
                    },
                    1024: {
                      slidesPerView: 3.5,
                      spaceBetween: 15
                    },
                    768: {
                      slidesPerView: 2.8,
                      spaceBetween: 15
                    },
                    640: {
                      slidesPerView: 2.5,
                      spaceBetween: 15
                    },
                    576: {
                      slidesPerView: 2.2,
                      spaceBetween: 15
                    },
                    0: {
                      slidesPerView: 1.75,
                      spaceBetween: 15
                    }
                  }
                }}
              />
            </EndlessContainer>
          )}
        </div>

        {fields.cta && (
          <div className="flex justify-center mt-10">
            <LosseLink src={fields.cta} className="button-mine-shaft-opaque" />
          </div>
        )}
      </div>
    </section>
  )
}

const PostTypes: { [key: string]: any } = {
  Post: PostItem,
  Reference: ReferenceItem,
  Market: MarketItem
}

function Slider({ fields, settings }: { fields: Page_Flexcontent_Flex_Slider; settings: SwiperProps }) {
  return (
    <Swiper
      modules={[Pagination, Mousewheel, Navigation]}
      mousewheel={{
        forceToAxis: true
      }}
      pagination={{ type: 'progressbar' }}
      navigation
      {...settings}
    >
      {fields.posts?.map((post, index) => {
        const Component = PostTypes[post?.__typename as string] || PostTypes.Post

        return (
          <SwiperSlide key={`${fields?.posts?.length}-${index}`}>
            <Component post={post} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
