import type { Page_Flexcontent_Flex_Links } from '~/graphql/types'
import { losseContentParse, LosseLink } from '@ubo/losse-sjedel'
import EndlessContainer from '~/components/elements/EndlessContainer'

export default function LinksDefault({ fields }: { fields: Page_Flexcontent_Flex_Links }) {
  return (
    <section>
      <EndlessContainer className="xl:max-w-screen-xl">
        <div className="w-full overflow-auto pr-4 flex py-2 section-text-bottom">
          <div className="bg-hh-blue bg-opacity-[.15] px-7 flex items-center rounded-3xl w-fit whitespace-nowrap">
            {fields.title && fields.enableTitle && <div className="text-black opacity-80 paragraph pr-4">{fields.title}</div>}
            <div className="flex items-center">
              {fields.items?.map((item, index) => (
                <LosseLink
                  key={index}
                  to={item?.link?.url}
                  target={item?.link?.target as string}
                  className="text-hh-mine-shaft text-opacity-50 font-hh-heading py-3 px-4 button-base-ring hover:bg-hh-blue hover:text-white focus:ring-offset-white focus:ring-hh-blue"
                >
                  {losseContentParse(item?.link?.title)}
                </LosseLink>
              ))}
            </div>
          </div>
        </div>
      </EndlessContainer>
    </section>
  )
}
