/* eslint-disable react-hooks/exhaustive-deps */
import { useLosseLayout } from '@ubo/losse-sjedel'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean
  onClose?: () => void
}

export default function Modal({ open, onClose, ...rest }: ModalProps): JSX.Element {
  const layout = useLosseLayout()
  const [isOpen, setOpen] = useState(false)

  function handleKeydown(event: KeyboardEvent) {
    if (isOpen && event.key === 'Escape') {
      close()
    }
  }

  function close() {
    setOpen(false)

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    if (open === undefined) {
      return
    }

    setOpen(open)
  }, [open])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    if (isOpen && layout.isScrollable) {
      layout.setScrollable(false)
    }

    if (!isOpen && !layout.isScrollable) {
      layout.setScrollable(true)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          exit={{
            opacity: 0,
            scale: 1.1
          }}
          animate={{
            opacity: 1,
            scale: 1
          }}
          initial={{
            opacity: 0,
            scale: 1.1
          }}
          transition={{
            duration: 0.2
          }}
          className="fixed inset-0 z-[99]"
        >
          <div className="absolute inset-0 bg-black/50 z-10" onClick={close} />
          <div className="absolute inset-x-0 inset-y-14 z-20">
            <div className="container max-w-screen-lg h-full overflow-auto">
              <div className="bg-white shadow relative">
                <button type="button" onClick={close} className="absolute right-5 md:right-10 top-5 md:top-10 text-hh-mine-shaft">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24 1.414L22.586 0L12 10.586L1.414 0L0 1.414L10.586 12L0 22.586L1.414 24L12 13.414L22.586 24L24 22.586L13.414 12L24 1.414Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <div className="p-5 md:p-10">{rest.children}</div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
