import type { Page_Flexcontent_Flex_Employees } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'

export default function ExhibitionDetails({ fields }: { fields: Page_Flexcontent_Flex_Employees }) {
  return (
    <section className="section">
      <div className="container xl:max-w-screen-xl">
        <div className="grid lg:grid-cols-3">
          <div className="col-span-2">
            {fields.description && <Content className="content mb-12">{fields.description}</Content>}
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-8 lg:gap-x-10 gap-y-16 lg:gap-y-20">
              {fields.items?.map((item) => (
                <div key={item?.title} className="relative h-full">
                  <div className="absolute -top-6 flex justify-center w-full">
                    <LossePlaatjie
                      src={item?.employee?.image}
                      maxWidth={200}
                      className="w-28 h-28 rounded-full overflow-hidden object-cover box-shadow"
                    />
                  </div>
                  <div className="bg-white box-shadow px-2 pb-3 pt-12 mt-14 h-[calc(100%-3.5rem)] font-hh-heading text-center">
                    <h3 className="text-lg">{item?.title}</h3>
                    <h4 className="text-hh-mine-shaft/50 mt-1">{item?.employee?.function}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
