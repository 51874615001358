import clsx from 'clsx'
import Loading from '../Loading'

export default function FormLoading({ backgroundColor = 'white' }: { backgroundColor?: string }) {
  return (
    <div
      className={clsx(
        backgroundColor === 'white' && 'bg-white/50',
        backgroundColor === 'concrete' && 'bg-hh-concrete/50 ',
        'absolute z-10 inset-0 flex items-center justify-center'
      )}
    >
      <Loading />
    </div>
  )
}
