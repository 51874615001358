import CookiebotDeclaration from '~/components/CookiebotDeclaration'
import { ClientOnly } from '~/components/elements/ClientOnly'
import type { Page_Flexcontent_Flex_CookiePolicy } from '~/graphql/types'

export default function CookiePolicy({ fields }: { fields: Page_Flexcontent_Flex_CookiePolicy }) {
  return (
    <section id="CookiePolicy">
      <div className="container xl:max-w-screen-xl section content">
        <ClientOnly>{() => <CookiebotDeclaration />}</ClientOnly>
      </div>
    </section>
  )
}
