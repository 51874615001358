import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import clsx from 'clsx'
import { useMatches } from '@ubo/losse-sjedel'

export default function TextImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const matches = useMatches();
  const isLighthouse = matches[0].data.isLighthouse;

  return (
    <section
      className={clsx(
        fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
        fields.backgroundColor === 'mineshaft' && 'bg-hh-mine-shaft',
        fields.backgroundColor === 'white' && 'bg-white'
      )}
    >
      <div className="container section xl:py-32">
        <div className="grid sm:grid-cols-2 gap-12 lg:gap-24">
          <div className="col-span-1 flex items-center">
            <Content className={clsx('content-lg w-full', fields.backgroundColor === 'mineshaft' && 'text-white')}>
              {fields?.description}
            </Content>
          </div>
          <div className="col-span-1 flex items-center">
            <div className="w-full">
              {!isLighthouse && (
                <iframe
                  width="100%"
                  className="h-[250px] sm:h-[200px] md:h-[300px] lg:h-[350px]"
                  src={fields?.video || ''}
                  title="Heinen Hopman"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  loading="lazy"
                />
              )}

              <div className="flex items-start justify-between xl:px-24">
                {fields?.stats?.map((edge, index) => (
                  <div key={index} className={clsx('text-center mt-14', fields.backgroundColor === 'mineshaft' && 'text-white')}>
                    <h3 className="title text-3xl md:text-5xl xl:text-6xl">{edge?.label}</h3>
                    <p className="font-hh-form font-light text-lg mt-3">{edge?.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
