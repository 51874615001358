import type { Page_Flexcontent_Flex_Images } from '~/graphql/types'
import clsx from 'clsx'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function ImagesDefault({ fields }: { fields: Page_Flexcontent_Flex_Images }) {
  const amount = fields.items?.length || 1
  let cols = 1

  if (amount % 1 === 0) {
    cols = 1
  }

  if (amount % 2 === 0) {
    cols = 2
  }

  if (amount % 3 === 0) {
    cols = 3
  }

  return (
    <section>
      <div className="container max-w-screen-xl section-text">
        <div
          className={clsx(
            'grid gap-5 sm:gap-8 xl:gap-12',
            cols === 1 && 'grid-cols-2',
            cols === 2 && 'grid-cols-2',
            cols === 3 && 'grid-cols-3'
          )}
        >
          {fields.items?.map((item, index) => (
            <div key={index} className="grid-col-1">
              <LossePlaatjie src={item} maxwidth={cols === 3 ? 500 : 700} className="object-cover w-full" />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
