import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex elements
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import FormShell from './Form/FormShell'
import CtaShell from './Cta/CtaShell'
import SliderShell from './Slider/SliderShell'
import ExhibitionsShell from './Exhibitions/ExhibitionsShell'
import LinksShell from './Links/LinksShell'
import AccordionShell from './Accordion/AccordionShell'
import QuoteShell from './Quote/QuoteShell'
import KnowledgeShell from './Knowledge/KnowledgeShell'
import ImagesShell from './Images/ImagesShell'
import OverviewShell from './Overview/OverviewShell'
import Fragment from './Fragment'
import LocationsShell from './Locations/LocationsShell'
import ExhibitionsDetails from './Exhibitions/ExhibitionDetails'
import HistoryShell from './History/HistoryShell'
import SearchShell from './Search/SearchShell'
import RelatedProducts from './RelatedProducts/RelatedProducts'
import Video from './Video/Video'
import CookiePolicy from './CookiePolicy/CookiePolicy'
import PdfViewer from './PdfViewer/PdfViewer'
import LatestPosts from './LatestPosts/LatestPosts'
import Employees from './Employees/Employees'
import InfoBlocksShell from './InfoBlocks/InfoBlocksShell'
import KnowledgeModal from './Overview/modals/KnowledgeModal'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
  isMain?: boolean
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent', isMain = true }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_Slider`]: SliderShell,
    [`${prefix}_Flex_Exhibitions`]: ExhibitionsShell,
    [`${prefix}_Flex_Links`]: LinksShell,
    [`${prefix}_Flex_Accordion`]: AccordionShell,
    [`${prefix}_Flex_Quote`]: QuoteShell,
    [`${prefix}_Flex_Knowledge`]: KnowledgeShell,
    [`${prefix}_Flex_Images`]: ImagesShell,
    [`${prefix}_Flex_Overview`]: OverviewShell,
    [`${prefix}_Flex_Employees`]: Employees,
    [`${prefix}_Flex_Locations`]: LocationsShell,
    [`${prefix}_Flex_ExhibitionDetails`]: ExhibitionsDetails,
    [`${prefix}_Flex_Search`]: SearchShell,
    [`${prefix}_Flex_History`]: HistoryShell,
    [`${prefix}_Flex_RelatedProducts`]: RelatedProducts,
    [`${prefix}_Flex_Video`]: Video,
    [`${prefix}_Flex_PdfViewer`]: PdfViewer,
    [`${prefix}_Flex_LatestPosts`]: LatestPosts,
    [`${prefix}_Flex_CookiePolicy`]: CookiePolicy,
    [`${prefix}_Flex_InfoBlocks`]: InfoBlocksShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  const modal = isMain && hasKnowledgeModal(flex as Page_Flexcontent['flex'][], prefix)

  return (
    <>
      {/* @ts-ignore */}
      <LosseFlex flex={flex} flexers={flexers} />

      {modal && <KnowledgeModal />}
    </>
  )
}

const KNOWLEDGE_FLEX_CONTENT = ['knowledge', 'overview', 'pdfviewer']

const hasKnowledgeModal = (flex: Page_Flexcontent['flex'][], prefix: string): boolean => {
  let response = false

  flex?.forEach((edge) => {
    // @ts-ignore
    const name = edge?.fieldGroupName?.toLowerCase().replace(`${prefix.toLowerCase()}_flex_`, '').toLowerCase()

    if (KNOWLEDGE_FLEX_CONTENT.includes(name)) {
      response = true
    }

    if (name === 'Fragment') {
      // @ts-ignore
      edge?.element?.flexContent?.flex?.forEach((node) => {
        if (KNOWLEDGE_FLEX_CONTENT.includes(node?.fieldGroupName?.replace('Fragment_FlexFragment_Flex_', ''))) {
          response = true
        }
      })

      return
    }
  })

  return response
}
