import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import CtaDefault from './CtaDefault'

const CtaShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: CtaDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default CtaShell
