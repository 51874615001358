import { Form, useLoaderData, useSearchParams } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import type { Page, Page_Flexcontent_Flex_Search, Product } from '~/graphql/types'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import { useRef } from 'react'

export default function SearchAZ({ fields }: { fields: Page_Flexcontent_Flex_Search }) {
  const data = useLoaderData<LoaderData<Page>>()
  const [searchParams] = useSearchParams()
  const submitRef = useRef<HTMLButtonElement>(null)

  const products = fields.postTypeToSearch?.nodes as Product[]
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'

  function getPostsStartingWithLetter(letter: string) {
    return products?.filter((product) => product.title?.toLowerCase().startsWith(letter))
  }

  return (
    <section>
      <div className="bg-hh-blue/20 py-10">
        <div className="container mx-auto flex flex-col items-center">
          <Content className="content mb-6">{fields.title}</Content>
          <Breadcrumbs />

          <Form action={new URL(data.request.url).pathname} method="get" className="relative flex mt-10">
            <input
              type="search"
              className="bg-white text-sm xl:w-[450px] font-hh-form font-light placeholder:font-hh-form placeholder:font-normal placeholder:text-hh-gray placeholder:text-opacity-50 text-black py-2 px-12 shadow-hh-inner border-white border-[1px] border-solid rounded-full"
              name="search-products"
              placeholder={'Search'}
              onChange={(event) => {
                const { value } = event.target as HTMLInputElement

                if (!value) {
                  submitRef.current?.click()
                }
              }}
              defaultValue={searchParams.get('search-products') || undefined}
            />
            <svg
              className="absolute top-0 left-4 bottom-0 my-auto"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 15.0586L11.8309 10.8895C12.9139 9.56498 13.4463 7.8749 13.3181 6.16882C13.19 4.46274 12.4109 2.8712 11.1422 1.72339C9.87348 0.575578 8.21211 -0.0406786 6.50176 0.00208561C4.79141 0.0448498 3.16292 0.743363 1.95314 1.95314C0.743363 3.16292 0.0448498 4.79141 0.00208561 6.50176C-0.0406786 8.21211 0.575578 9.87348 1.72339 11.1422C2.8712 12.4109 4.46274 13.19 6.16882 13.3181C7.8749 13.4463 9.56498 12.9139 10.8895 11.8309L15.0586 16L16 15.0586ZM6.6791 12.0053C5.62567 12.0053 4.5959 11.6929 3.72 11.1077C2.84411 10.5224 2.16143 9.6906 1.7583 8.71736C1.35517 7.74412 1.2497 6.67319 1.45521 5.64C1.66072 4.60681 2.168 3.65777 2.91288 2.91288C3.65777 2.168 4.60681 1.66072 5.64 1.45521C6.67319 1.2497 7.74412 1.35517 8.71736 1.7583C9.6906 2.16143 10.5224 2.84411 11.1077 3.72C11.6929 4.5959 12.0053 5.62567 12.0053 6.6791C12.0037 8.09121 11.4421 9.44504 10.4436 10.4436C9.44504 11.4421 8.09121 12.0037 6.6791 12.0053Z"
                fill="#888888"
                fillOpacity="1"
              />
            </svg>
            <button ref={submitRef} className="button-blue -ml-10 py-1" type="submit">
              Search
            </button>
          </Form>
        </div>
      </div>

      <div className="py-10 container">
        <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-x-5 gap-y-10">
          {Array.from(Array(26)).map((_, i) => {
            const foundProducts = getPostsStartingWithLetter(alphabet[i])
            if (foundProducts.length === 0) return null

            return (
              <div className="flex flex-col" key={alphabet[i]}>
                <strong className="uppercase font-hh-heading text-2xl font-normal mb-1 px-2">{alphabet[i]}</strong>
                <hr className="border-black" />
                {foundProducts.map((post) => (
                  <LosseLink
                    to={post.link || '/'}
                    className="px-2 sm:text-base font-hh-form text-sm font-light hover:underline mt-3"
                    key={post?.id}
                  >
                    {post.title}
                  </LosseLink>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
