import { LossePlaatjie, LosseLink } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import type { Post } from '~/graphql/types'
import type { Edge } from '../OverviewDefault'

export default function PostItem({ edge }: { edge: Edge<Post> }) {
  return (
    <LosseLink
      className="col-span-1 relative w-full flex items-end justify-start h-[250px] sm:h-[450px] overflow-hidden group"
      to={edge.node.uri}
    >
      <div className="absolute inset-0 z-0">
        <LossePlaatjie
          src={edge.node?.recap?.image}
          maxwidth={350}
          className="w-full h-full object-cover group-hover:scale-110 transition-all"
        />
      </div>
      <motion.div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black" />
      <div className="relative w-full z-20 px-4 py-5 sm:px-7 sm:py-10 text-white">
        <div className="flex items-center mb-4 overflow-x-auto no-scrollbar h-[26px] -mr-3 md:-mr-5 xl:-mr-7">
          {edge.node.categories?.nodes?.map((category) => (
            <div key={category?.databaseId} className="tag mr-2">
              {category?.name}
            </div>
          ))}
        </div>
        <h2 className="title text-base sm:text-2xl break-words line-clamp-4">{edge.node?.title}</h2>
        <div className="flex items-center justify-between mt-2 text-white/80">
          <h3 className="paragraph">{edge.node.__typename}</h3>
          <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-3 w-3">
            <path
              d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </LosseLink>
  )
}
