import React from 'react'

// Components
import FormContact from './FormContact'

export interface FormProps {
  fields: any
  index: number
}

interface PostProps {
  [key: string]: React.FC<FormProps>
}

const FormShell: React.FC<FormProps> = ({ fields, index }) => {
  const types: PostProps = {
    contact: FormContact,
    with_video: FormContact
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return <Component key={index} index={index} fields={fields} />
}

export default FormShell
