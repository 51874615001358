import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Links } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function LinksWithIconVertically({ fields }: { fields: Page_Flexcontent_Flex_Links }) {
  return (
    <div className={clsx('section', 'container max-w-6xl mx-auto')}>
      <div className="grid sm:grid-cols-3 grid-cols-2 sm:gap-12 gap-8">
        {fields.items?.map((item, index) => (
          <LosseLink
            className="flex flex-col items-center group text-hh-mine-shaft hover:text-hh-blue"
            to={item?.link?.url}
            key={item?.link?.title || index}
          >
            <div className="lg:w-[162px] lg:h-[162px] sm:w-[130px] sm:h-[130px] h-[80px] w-[80px] relative mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="162"
                height="162"
                fill="none"
                viewBox="0 0 162 162"
                className="absolute left-0 top-0 w-full h-full"
              >
                <path
                  fill="currentColor"
                  d="M81 5.89a75.11 75.11 0 110 150.22A75.11 75.11 0 0181 5.89zM81 0A81.132 81.132 0 000 81a81.116 81.116 0 0081 81 81.132 81.132 0 0081-81A81.147 81.147 0 0081 0z"
                ></path>
                <path
                  fill="currentColor"
                  d="M81 13.74a67.304 67.304 0 11-25.754 129.485 67.299 67.299 0 01-36.417-36.426 67.285 67.285 0 01-5.118-25.755 67.288 67.288 0 0167.29-67.303zm0-1.575a68.953 68.953 0 00-68.88 68.88A68.953 68.953 0 0081 149.923a68.956 68.956 0 0068.88-68.88A68.956 68.956 0 0081 12.165z"
                ></path>
              </svg>
              {item?.icon?.mediaItemUrl && (
                <img
                  className="absolute left-0 top-0 bottom-0 right-0 m-auto lg:w-16 lg:h-16 sm:w-12 sm:h-12 object-contain group-hover:filter-blue"
                  src={item?.icon?.mediaItemUrl}
                  alt={item?.icon?.altText || 'Icon'}
                />
              )}
            </div>

            <Content className="text-center lg:text-2xl sm:text-xl text-lg font-hh-heading transition-colors">{item?.text}</Content>
          </LosseLink>
        ))}
      </div>
    </div>
  )
}
