import React from 'react'

// Components
import BannerDefault from './BannerDefault'
import BannerLanding from './BannerLanding'

export interface BannerProps {
  fields: any
  index: number
}

interface BannersProps {
  [key: string]: React.FC<BannerProps>
}

const BannerShell: React.FC<BannerProps> = ({ fields, index }) => {
  const banners: BannersProps = {
    default: BannerDefault,
    landing: BannerLanding
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return <Component key={index} index={index} fields={fields} />
}

export default BannerShell
