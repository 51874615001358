import React from 'react'

export interface ButtonProps extends React.ComponentPropsWithoutRef<any> {
  onClick?: () => void
  href?: string
}

const Button: React.FC<ButtonProps> = ({ children, className = '', ...props }) => {
  return (
    <a className={`${className} button-blue hover:text-white text-white py-2 px-3`} {...props}>
      {children}
    </a>
  )
}

export default Button
