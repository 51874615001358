import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function CtaDefault({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section
      className={clsx(
        fields.width === 'full' && 'bg-hh-mine-shaft',
        !!fields.sticky && 'fixed z-30 bottom-0 inset-x-0 box-shadow lg:block hidden'
      )}
    >
      <div className="container xl:max-w-screen-xl">
        <div className={clsx(fields.width === 'container' && 'bg-hh-mine-shaft px-5')}>
          <div
            className={clsx(!fields.sticky && 'py-6', !!fields.sticky && 'py-4', 'max-w-5xl mx-auto lg:flex justify-between items-center')}
          >
            <Content className="text-white text-center lg:text-align-inherit children-headings:font-bold lg:children-headings:mr-14 children-headings:text-2xl lg:children-headings:text-3xl children-headings:uppercase children-p:text-sm leading-sm lg:leading-m sm:children-p:text-m lg:children-p:text-lg font-hh-heading lg:flex items-center">
              {fields?.description}
            </Content>
            {!!fields.link && (
              <div className="text-center mt-5 lg:mt-0">
                <LosseLink src={fields.link} className={clsx(fields.width === 'container' ? 'button-blue' : 'button-green')} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
