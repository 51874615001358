import type { Exhibition, Page_Flexcontent_Flex_ExhibitionDetails } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import dayjs from 'dayjs'

export default function ExhibitionDetails({ fields }: { fields: Page_Flexcontent_Flex_ExhibitionDetails }) {
  const { page } = useLoaderData<LoaderData<Exhibition>>()

  const sameYear = dayjs(page.recap?.dateStart, 'DD/MM/YY').format('YYYY') === dayjs(page.recap?.dateEnd, 'DD/MM/YY').format('YYYY')
  const sameMonth = dayjs(page.recap?.dateStart, 'DD/MM/YY').format('MM') === dayjs(page.recap?.dateEnd, 'DD/MM/YY').format('MM')

  return (
    <section className="section">
      <div className="container xl:max-w-screen-xl">
        <div className="grid grid-cols-12 gap-x-8 lg:gap-x-12 gap-y-10">
          <div className="order-3 sm:order-1 col-span-12 sm:col-span-8 lg:col-span-7 bg-hh-concrete px-8 py-12 flex flex-col font-hh-heading">
            <div className="grid grid-cols-2 gap-y-8 lg:gap-y-10 gap-x-10 lg:gap-x-20">
              <div className="col-span-1">
                <h4 className="text-m lg:text-lg text-hh-blue">Date</h4>
                <h3 className="text-xl lg:text-2xl">
                  {dayjs(page.recap?.dateStart, 'DD/MM/YYYY').format(`MMMM DD${sameYear ? '' : ' YYYY'}`)} -{' '}
                  {dayjs(page.recap?.dateEnd, 'DD/MM/YYYY').format(`${sameMonth ? '' : 'MMMM '}DD${sameYear ? ', ' : ''}YYYY`)}
                </h3>
              </div>
              <div className="col-span-1">
                <h4 className="text-m lg:text-lg text-hh-blue">Location</h4>
                <h3 className="text-xl lg:text-2xl">{page.recap?.location}</h3>
              </div>
              {page.recap?.stand && (
                <div className="col-span-1">
                  <h4 className="text-m lg:text-lg text-hh-blue">Stand</h4>
                  <h3 className="text-2xl lg:text-3xl uppercase font-bold">{page.recap?.stand}</h3>
                </div>
              )}
              <div className="col-span-1">
                <h4 className="text-lg text-hh-blue">Address</h4>
                <a
                  href={`https://maps.google.com/?q=${page.recap?.address}`}
                  target="_blank"
                  className="text-xl lg:text-2xl group"
                  rel="noreferrer"
                >
                  <span className="group-hover:underline">{page.recap?.address}</span>
                  <span className="text-hh-mine-shaft/50 group-hover:text-hh-mine-shaft transition-colors text-m flex items-center mt-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                      <g clipPath="url(#clip0_1201_5885)">
                        <path
                          d="M12 0C9.34873 0.0029116 6.80688 1.05742 4.93215 2.93215C3.05742 4.80688 2.00291 7.34873 2 10C2 15.282 10.4 22.533 11.354 23.343L12 23.889L12.646 23.343C13.6 22.533 22 15.282 22 10C21.9971 7.34873 20.9426 4.80688 19.0679 2.93215C17.1931 1.05742 14.6513 0.0029116 12 0V0ZM12 15C11.0111 15 10.0444 14.7068 9.22215 14.1573C8.3999 13.6079 7.75904 12.827 7.3806 11.9134C7.00216 10.9998 6.90315 9.99445 7.09607 9.02455C7.289 8.05464 7.7652 7.16373 8.46447 6.46447C9.16373 5.7652 10.0546 5.289 11.0245 5.09607C11.9945 4.90315 12.9998 5.00216 13.9134 5.3806C14.827 5.75904 15.6079 6.3999 16.1573 7.22215C16.7068 8.04439 17 9.01109 17 10C16.9984 11.3256 16.4711 12.5964 15.5338 13.5338C14.5964 14.4711 13.3256 14.9984 12 15Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12 13.0005C13.6569 13.0005 15 11.6573 15 10.0005C15 8.34363 13.6569 7.00049 12 7.00049C10.3431 7.00049 9 8.34363 9 10.0005C9 11.6573 10.3431 13.0005 12 13.0005Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1201_5885">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Google Maps
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="order-2 col-span-12 sm:col-span-4 lg:col-span-5 flex justify-center items-center">
            <LossePlaatjie src={page.recap?.image} width="300" className="w-[200px] sm:w-[250px] max-w-full" />
          </div>
        </div>
      </div>
    </section>
  )
}
