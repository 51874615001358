import { useState } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import type { Component_Header_Information_Languages, Maybe } from '~/graphql/types'
import { LosseLink, useLocation } from '@ubo/losse-sjedel'
import { useDetectClickOutside } from 'react-detect-click-outside'

export default function LanguageSwitch({
  className = '',
  response = true,
  languages
}: {
  className?: string
  response?: boolean
  languages: Maybe<Maybe<Component_Header_Information_Languages>[]> | undefined
}) {
  const [showLanguages, setShowLanguages] = useState(false)
  const location = useLocation()
  const activeLanguage =
    languages?.find((language) => language?.link?.url === location.pathname) || languages?.find((language) => language?.link?.url === '/')
  const activeTitle = activeLanguage?.link?.title as keyof typeof flags
  const ref = useDetectClickOutside({ onTriggered: () => setShowLanguages(false) })

  function handleShowLanguages() {
    if (showLanguages) {
      setShowLanguages(false)
    }
    if (!showLanguages) {
      setShowLanguages(true)
    }
  }

  return (
    <motion.div
      className={clsx('relative', className)}
      onHoverStart={() => setShowLanguages(true)}
      onHoverEnd={() => setShowLanguages(false)}
      onClick={() => handleShowLanguages()}
      role="button"
      ref={ref}
    >
      <motion.div role="button">
        <div
          className={clsx(
            showLanguages && response && 'bg-hh-concrete rounded-t-[15px] md:rounded-t-[19px]',
            !showLanguages && response && 'rounded-[15px] md:rounded-[19px]',
            showLanguages && !response && 'bg-hh-concrete rounded-t-[19px]',
            !showLanguages && !response && 'rounded-[19px]',
            'pointer-events-none border border-solid border-white text-white flex items-center p-1 transition-all'
          )}
        >
          <div className={clsx(response && 'w-5 h-5 md:w-7 md:h-7', !response && 'w-7 h-7', 'mr-2 rounded-full overflow-hidden')}>
            {flags[activeTitle].icon}
          </div>
          <div className={clsx('uppercase text-s w-9 text-left', showLanguages && 'text-hh-mine-shaft', !showLanguages && 'text-white')}>
            {flags[activeTitle].label}
          </div>
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('pr-1 w-4', showLanguages && 'text-hh-mine-shaft', !showLanguages && 'text-white')}
          >
            <path
              d="M4.66986 5C4.49354 5.00031 4.3189 4.96504 4.15598 4.89621C3.99305 4.82738 3.84506 4.72635 3.72049 4.59893L0.166748 0.969423L1.11611 0L4.66986 3.62882L8.22361 0L9.17297 0.969423L5.61923 4.59824C5.49472 4.72579 5.34675 4.82695 5.18382 4.89589C5.02089 4.96484 4.84622 5.00022 4.66986 5Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </motion.div>
      <motion.div
        animate={showLanguages ? 'open' : 'closed'}
        variants={{
          closed: {
            opacity: 0,
            height: 0
          },
          open: {
            opacity: 1,
            height: 'auto'
          }
        }}
        transition={{ duration: 0.2 }}
        className={clsx(
          response && 'rounded-b-[15px] md:rounded-b-[19px]',
          !response && 'rounded-b-[19px]',
          'h-0 shadow opacity-0 absolute top-[calc(100%-1px)] bg-hh-concrete inset-x-0 text-white z-10 border-x border-white border-b border-solid overflow-hidden'
        )}
      >
        {languages
          ?.filter((language) => language?.link?.title !== activeTitle)
          ?.map((language) => {
            const title = language?.link?.title as keyof typeof flags

            return (
              <LosseLink
                key={language?.link?.title}
                to={language?.link?.url}
                target={language?.link?.target as string}
                className="flex items-center pl-1 py-1 hover:bg-hh-blue/50"
              >
                <div className={clsx(response && 'w-5 h-5 md:w-7 md:h-7', !response && 'w-7 h-7', 'mr-2 rounded-full overflow-hidden')}>
                  {flags[title]?.icon}
                </div>
                <div className="uppercase text-s w-9 text-left text-hh-mine-shaft">{flags[title]?.label}</div>
              </LosseLink>
            )
          })}
      </motion.div>
    </motion.div>
  )
}

const flags = {
  한국어: {
    label: '한국어',
    icon: (
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <circle cx="16" cy="16" fill="#f2f2f2" r="16" />
        <g fill="#28292b">
          <path d="m4.269 10.316 3.35-3.522.749.778-3.35 3.523z" />
          <path d="m5.346 11.461 3.349-3.523.75.778-3.349 3.523z" />
          <path d="m6.391 12.557 3.349-3.522.75.777-3.35 3.523z" />
          <path d="m23.432 20.174 1.315-1.385.75.779-1.317 1.388z" />
          <path d="m22.147 23.09-.751-.778 1.289-1.356.747.78z" />
          <path d="m24.521 21.307 1.303-1.372.749.778-1.306 1.375z" />
          <path d="m23.226 24.234-.75-.779 1.297-1.367.748.781z" />
          <path d="m26.317 23.178-.748-.781 1.299-1.366.748.778z" />
          <path d="m24.271 25.332-.749-.779 1.302-1.371.748.78z" />
          <path d="m26.406 8.795 1.325 1.375-.745.784-1.327-1.378z" />
          <path d="m23.616 7.451.746-.783 1.297 1.346-.747.781z" />
          <path d="m22.523 8.579.745-.784 3.367 3.499-.744.784z" />
          <path d="m23.531 11.81.748-.781 1.308 1.356-.745.784z" />
          <path d="m21.472 9.672.744-.784 1.313 1.362-.748.781z" />
          <path d="m6.434 19.518.745-.783 3.371 3.502-.745.783z" />
          <path d="m8.141 21.999 1.313 1.363-.745.783-1.316-1.364z" />
          <path d="m5.341 20.646.745-.784 1.307 1.357-.747.78z" />
          <path d="m4.29 21.739.744-.783 3.371 3.497-.745.783z" />
        </g>
        <path
          d="m21.71 16a5.827 5.827 0 0 1 -5.695 5.952 5.827 5.827 0 0 1 -5.694-5.952 5.827 5.827 0 0 1 5.694-5.953 5.828 5.828 0 0 1 5.695 5.953z"
          fill="#2e4593"
        />
        <path
          d="m21.68 16a5.709 5.709 0 0 1 -.057.832c-.165-1.179-.781-3.306-3.114-3.347a2.444 2.444 0 0 0 -2.649 1.727c-.521 1.351-.577 3-2.867 3a2.56 2.56 0 0 1 -2.7-2.121.648.648 0 0 1 0-.088 6.139 6.139 0 0 1 .667-2.8 5.669 5.669 0 0 1 5.03-3.159 5.829 5.829 0 0 1 5.69 5.956z"
          fill="#db2e2e"
        />
      </svg>
    )
  },
  中文: {
    label: '中文',
    icon: (
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <circle cx="16" cy="16" fill="#d80027" r="16" />
        <g fill="#ffda44">
          <path d="m17.514 11.602-4.472.007-.013.006.006-.013-1.366-4.43-1.215 4.437-4.43-.007 3.68 2.603h.022l-.042.03-1.498 4.41 3.577-2.768.006-.02.006.02 3.574 2.768-1.498-4.41-.039-.03h.013z" />
          <path d="m25.129 18.719h-.915l-.28-.904-.248.904h-.903l.751.533h.006l-.009.007-.306.9.728-.563.001-.005.002.005.732.563-.31-.9-.009-.007h.006z" />
          <path d="m20.07 23.412-.026-.95-.484.795-.866-.266.58.731.002.001-.009.003-.534.775.85-.327.003-.005.001.005.544.755-.048-.954-.003-.003.866-.294z" />
          <path d="m23.631 14.05.905-.044.005-.004-.002.007.306.894.223-.927-.004-.01.004.003.908-.003-.76-.53h-.004l.004-.003.248-.914-.689.608-.748-.527.341.88h-.006z" />
          <path d="m22.702 8.579.007.002.867-.28-.873-.276-.003.001.003-.004-.017-.951-.49.792-.863-.276.57.735.008.002-.014.004-.541.766.853-.317.004-.005v.005l.538.765-.042-.954z" />
        </g>
      </svg>
    )
  },
  Turkce: {
    label: 'TR',
    icon: (
      <svg fill="none" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <g id="36 Multicolor / Flag / mc_36_flag_turkey">
          <g id="mc_36_flag_Turkey">
            <path
              id="Oval"
              d="m18 36c9.9411 0 18-8.0589 18-18 0-9.94113-8.0589-18-18-18-9.94113 0-18 8.05887-18 18 0 9.9411 8.05887 18 18 18z"
              fill="#d80027"
            />
            <path
              id="Shape"
              clipRule="evenodd"
              d="m8.145 18c.00716 2.8052 2.2826 5.0759 5.0878 5.077.8757-.0001 1.6994-.2121 2.4192-.612-1.1682 1.1458-2.7397 1.7868-4.376 1.785-3.45786 0-6.261-2.8031-6.261-6.261 0-3.4578 2.80314-6.261 6.261-6.261 1.6363-.0017 3.2078.6393 4.376 1.785-.72-.378-1.544-.6-2.42-.6-2.8088.0017-5.08535 2.2782-5.087 5.087zm10.595-1.265-1.477-2.035-.001 2.524-2.392.776 2.379.768v2.514l1.48-2.033 2.4.778-1.477-2.035 1.48-2.033z"
              fill="#f0f0f0"
              fillRule="evenodd"
            />
          </g>
        </g>
      </svg>
    )
  },
  Romania: {
    label: 'RO',
    icon: (
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <path
          d="m345.043 15.922c-27.734-10.289-57.729-15.922-89.043-15.922-31.314-.001-61.31 5.633-89.043 15.922l-22.261 240.077 22.261 240.077c27.732 10.292 57.728 15.924 89.043 15.924 31.313 0 61.31-5.632 89.043-15.924l22.26-240.076z"
          fill="#ffda44"
        />
        <path d="m512 256c0-110.07-69.472-203.907-166.957-240.077v480.156c97.485-36.174 166.956-130.007 166.957-240.079z" fill="#d80027" />
        <path
          d="m0 255.999c0 110.073 69.472 203.906 166.955 240.079l.001-480.154c-97.484 36.17-166.956 130.004-166.956 240.075z"
          fill="#0052b4"
        />
      </svg>
    )
  },
  Espanol: {
    label: 'ES',
    icon: (
      <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <path
          d="m0 256c0 31.314 5.633 61.31 15.923 89.043l240.077 22.261 240.077-22.261c10.29-27.733 15.923-57.729 15.923-89.043s-5.633-61.31-15.923-89.043l-240.077-22.261-240.077 22.261c-10.29 27.733-15.923 57.729-15.923 89.043z"
          fill="#ffda44"
        />
        <g fill="#d80027">
          <path d="m496.077 166.957c-36.171-97.484-130.006-166.957-240.077-166.957s-203.906 69.473-240.077 166.957z" />
          <path d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957z" />
        </g>
      </svg>
    )
  },
  Deutsch: {
    label: 'DE',
    icon: (
      <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <path
          d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957l-240.077-22.26z"
          fill="#ffda44"
        />
        <path d="m256 0c-110.071 0-203.906 69.472-240.077 166.957l240.077 22.26 240.077-22.261c-36.171-97.484-130.006-166.956-240.077-166.956z" />
        <path
          d="m15.923 166.957c-10.29 27.733-15.923 57.729-15.923 89.043s5.633 61.31 15.923 89.043h480.155c10.29-27.733 15.922-57.729 15.922-89.043s-5.632-61.31-15.923-89.043z"
          fill="#d80027"
        />
      </svg>
    )
  },
  Brazilian: {
    label: 'BR',
    icon: (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
        <circle fill="#00C85F" cx="256" cy="256" r="256" />
        <path
          fill="#FFF046"
          d="M246.207,103.892L28.673,248.915c-5.241,3.495-5.241,11.196,0,14.69l217.534,145.022
	c5.93,3.954,13.656,3.954,19.586,0l217.534-145.022c5.241-3.495,5.241-11.196,0-14.69L265.793,103.892
	C259.863,99.938,252.137,99.938,246.207,103.892z"
        />
        <circle fill="#4B82E1" cx="256" cy="256" r="105.931" />
        <path
          fill="#FFFFFF"
          d="M165.515,201.127c-3.47,5.709-6.403,11.761-8.758,18.111c49.205-3.345,128.166,5.419,197.503,76.211
	c2.472-6.151,4.404-12.57,5.692-19.229C292.095,211.143,216.738,199.457,165.515,201.127z"
        />
      </svg>
    )
  },
  Italiano: {
    label: 'IT',
    icon: (
      <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
        <path d="m512 256c0-110.071-69.472-203.906-166.957-240.077v480.155c97.485-36.172 166.957-130.007 166.957-240.078z" fill="#d80027" />
        <path d="m0 256c0 110.071 69.472 203.906 166.957 240.077v-480.154c-97.485 36.171-166.957 130.006-166.957 240.077z" fill="#6da544" />
      </svg>
    )
  },
  English: {
    label: 'EN',
    icon: (
      <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
        <g fill="#0052b4">
          <path d="m52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178z" />
          <path d="m503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076z" />
          <path d="m8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075z" />
          <path d="m411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177z" />
          <path d="m100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102v-133.176z" />
          <path d="m189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075z" />
          <path d="m322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075z" />
          <path d="m370.005 322.784 89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076z" />
        </g>
        <g fill="#d80027">
          <path d="m509.833 222.609h-220.44-.001v-220.442c-10.931-1.423-22.075-2.167-33.392-2.167-11.319 0-22.461.744-33.391 2.167v220.44.001h-220.442c-1.423 10.931-2.167 22.075-2.167 33.392 0 11.319.744 22.461 2.167 33.391h220.44.001v220.442c10.931 1.423 22.073 2.167 33.392 2.167 11.317 0 22.461-.743 33.391-2.167v-220.44-.001h220.442c1.423-10.931 2.167-22.073 2.167-33.392 0-11.317-.744-22.461-2.167-33.391z" />
          <path d="m322.783 322.784 114.236 114.236c5.254-5.252 10.266-10.743 15.048-16.435l-97.802-97.802h-31.482z" />
          <path d="m189.217 322.784h-.002l-114.235 114.235c5.252 5.254 10.743 10.266 16.435 15.048l97.802-97.804z" />
          <path d="m189.217 189.219v-.002l-114.236-114.237c-5.254 5.252-10.266 10.743-15.048 16.435l97.803 97.803h31.481z" />
          <path d="m322.783 189.219 114.237-114.238c-5.252-5.254-10.743-10.266-16.435-15.047l-97.802 97.803z" />
        </g>
      </svg>
    )
  }
}
