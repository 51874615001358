import { LosseBlogBink, LossePlaatjie, LosseLink, useLosseBlogBink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Overview, Product } from '~/graphql/types'
import type { Edge } from './OverviewDefault'
import Ordering from '../../elements/blog/Ordering'
import Pagination from '~/components/elements/blog/Pagination'

export default function OverviewProducts({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  if (!fields.postType?.pageInfo) {
    return null
  }

  return (
    <section id='OverviewProducts'>
      <LosseBlogBink pageInfo={fields.postType?.pageInfo}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  const { isFilterActive, toggleFilter, hasNextPage, hasPrevPage, clear } = useLosseBlogBink()
  const products = fields.postType?.edges as Edge<Product>[]

  const isAnyFilterActive = fields.filters?.some((category) => {
    return isFilterActive(category?.databaseId)
  })

  return (
    <div>
      <div className="section bg-hh-concrete">
        <div className="container max-w-screen-xl">
          <Content className="content mb-6">{fields.title}</Content>
          <div className="flex items-center flex-wrap sm:gap-4 gap-2">
            <span className="font-hh-form text-sm">Filter by category</span>

            <button
              onClick={() => clear()}
              className={clsx(
                'px-4 py-2 rounded-full sm:text-base text-sm font-hh-heading text-white',
                isAnyFilterActive ? 'bg-hh-gray' : 'bg-hh-blue'
              )}
              type="button"
            >
              <span className="leading-none mt-[-1px] block">Select all</span>
            </button>

            {fields.filters?.map((category) => (
              <button
                onClick={() => toggleFilter(category?.databaseId)}
                className={clsx(
                  'px-4 py-2 rounded-full sm:text-base text-sm font-hh-heading text-white',
                  isFilterActive(category?.databaseId) ? 'bg-hh-blue' : 'bg-hh-gray'
                )}
                key={category?.databaseId}
                type="button"
              >
                <span className="leading-none mt-[-1px] block">{category?.name}</span>
              </button>
            ))}
          </div>

          <div className="mb-6 mt-8">
            <Ordering />
          </div>

          <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 mb-6">
            {products?.map((product) => (
              <LosseLink to={product.node.uri} key={product?.node.databaseId} className="bg-white p-6">
                <LossePlaatjie src={product.node.recap?.image} maxwidth={300} className="mb-6 object-contain" />

                <strong className="font-hh-heading sm:text-base text-sm uppercase">{product.node.title}</strong>
              </LosseLink>
            ))}
          </div>

          <div className="flex justify-between">
            {fields.cta && (
              <LosseLink to={fields.cta?.url} target={fields.cta?.target || undefined} className="flex items-center">
                <span className="font-hh-heading mr-4">{fields.cta?.title}</span>
                <img src="/icons/chevron-right-black.svg" className="" alt="Chevron right" />
              </LosseLink>
            )}

            {(hasNextPage || hasPrevPage) && <Pagination />}
          </div>
        </div>
      </div>

      <div className="bg-hh-mine-shaft py-10">
        <div className="container max-w-screen-lg flex items-center text-white justify-between">
          <h2 className="font-hh-heading text-2xl">{fields.support?.title}</h2>

          <LosseLink src={fields.support?.cta} className="button-blue" />
        </div>
      </div>
    </div>
  )
}
