import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Slider_Posts } from '~/graphql/types'

export default function ReferenceItem({ post }: { post: Page_Flexcontent_Flex_Slider_Posts }) {
  return (
    <LosseLink
      className="bg-hh-silver w-full flex items-end justify-start relative h-[250px] overflow-hidden group"
      to={post?.uri as string}
    >
      <div className="absolute inset-0 z-0">
        <LossePlaatjie
          src={post?.recap?.image}
          maxwidth={600}
          className="w-full h-full object-cover group-hover:scale-105 transition-all"
        />
      </div>
      <div className="relative z-20 p-3 sm:p-5 bg-black/50 text-white w-full">
        <h2 className="title text-lg sm:text-xl lg:text-xl group-hover:underline">{post?.title}</h2>
      </div>
    </LosseLink>
  )
}
