import type { Category, ContentNode, Knowledge_Recap, Maybe, Page_Flexcontent_Flex_Overview, WpPageInfo } from '~/graphql/types'
import { LosseBlogBink, useLosseBlogBink } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import { Fragment, useState } from 'react'
import clsx from 'clsx'
import { useSearchParams } from '@ubo/losse-sjedel'

import KnowledgeItem from './default-item-types/KnowledgeItem'
import PostItem from './default-item-types/PostItem'
import ReferenceItem from './default-item-types/ReferenceItem'
import ProductItem from './default-item-types/ProductItem'
import Pagination from '~/components/elements/blog/Pagination'
import Ordering from '~/components/elements/blog/Ordering'
import useMediaQuery from '~/hooks/useMediaQuery'
import Message from '~/components/elements/Message'
import CustomItem from './default-item-types/CustomItem'
import LocationItem from './default-item-types/LocationItem'
import VacancyItem from './default-item-types/VacancyItem'

export type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
    title?: string
    recap?: Knowledge_Recap
  }
}

const SHOW_MAX_FILTERS = 10

export const getPostTypeName = (fields: Page_Flexcontent_Flex_Overview) => {
  const items = fields.postType?.edges as Edge[]
  const postTypeName = items?.[0]?.node?.__typename

  return postTypeName
}

export default function OverviewDefault({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  if (!fields.postType?.pageInfo) {
    return null
  }

  return (
    <>
      <section id="overview">
        <LosseBlogBink
          pageInfo={fields.postType?.pageInfo as Maybe<WpPageInfo> | undefined}
          className="container section xl:max-w-[1365px]"
        >
          <Overview fields={fields} />
        </LosseBlogBink>
      </section>
    </>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const { clear } = useLosseBlogBink()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const items = fields.postType?.edges as Edge[]
  const postTypeName = getPostTypeName(fields)
  const parentFilters = fields.filters?.filter((filter) => !filter?.parentDatabaseId)
  const parentIds = parentFilters?.map((filter) => filter?.databaseId || 0) as number[]
  const [open, setOpen] = useState<number[]>(parentIds)
  const [search, setSearch] = useState(searchParams.get('_q') || '')

  return (
    <>
      <div className="lg:grid grid-cols-12 gap-12">
        <div className="col-span-3 p-5 bg-hh-blue/10 lg:p-0 lg:bg-transparent">
          <div className="flex items-center justify-between">
            <h2 className="title text-2xl font-light">Filters</h2>

            <button type="button" onClick={() => setFiltersOpen(!isFiltersOpen)} className="lg:hidden button-link">
              {isFiltersOpen ? 'Hide' : 'Show'}
            </button>
          </div>

          <motion.div
            animate={isFiltersOpen || !isMobile ? 'open' : 'closed'}
            initial="closed"
            variants={{
              open: {
                height: 'auto'
              },
              closed: {
                height: 0
              }
            }}
            className="overflow-hidden"
          >
            <div className="flex items-center mt-7 lg:mt-14 px-5 input overflow-hidden h-10">
              <div className="text-hh-mine-shaft/50">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.1078 15.0586L11.9259 10.8895C13.0122 9.56498 13.5463 7.8749 13.4177 6.16882C13.2891 4.46274 12.5077 2.8712 11.2351 1.72339C9.96245 0.575578 8.29598 -0.0406786 6.58036 0.00208561C4.86475 0.0448498 3.23125 0.743363 2.01775 1.95314C0.804244 3.16292 0.103582 4.79141 0.0606858 6.50176C0.01779 8.21211 0.635943 9.87348 1.78728 11.1422C2.93863 12.4109 4.53507 13.19 6.24639 13.3181C7.95772 13.4463 9.653 12.9139 10.9816 11.8309L15.1635 16L16.1078 15.0586ZM6.75824 12.0053C5.70157 12.0053 4.66863 11.6929 3.79004 11.1077C2.91145 10.5224 2.22668 9.6906 1.82231 8.71736C1.41794 7.74412 1.31214 6.67319 1.51828 5.64C1.72443 4.60681 2.23326 3.65777 2.98044 2.91288C3.72762 2.168 4.67958 1.66072 5.71595 1.45521C6.75232 1.2497 7.82654 1.35517 8.80277 1.7583C9.77901 2.16143 10.6134 2.84411 11.2005 3.72C11.7875 4.5959 12.1009 5.62567 12.1009 6.6791C12.0993 8.09121 11.5359 9.44504 10.5343 10.4436C9.5327 11.4421 8.1747 12.0037 6.75824 12.0053Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="px-4 flex-1 w-12">
                <input
                  type="text"
                  name="_q"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  onKeyDown={(e) => {
                    // on enter key, to prevent using the form which scrolls you up
                    if (e.keyCode === 13) {
                      e.preventDefault()
                      searchParams.set('_q', search)
                      setSearchParams(searchParams, {
                        replace: true,
                        state: {
                          scroll: false
                        }
                      })
                    }
                  }}
                  className="bg-transparent placeholder:text-hh-mine-shaft/50 placeholder:text-sm"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="font-hh-form text-sm uppercase text-hh-mine-shaft/75 hover:cursor-pointer hover:underline font-bold"
                  onClick={() => {
                    searchParams.set('_q', search)
                    setSearchParams(searchParams, {
                      replace: true,
                      state: {
                        scroll: false
                      }
                    })
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
            {parentFilters && parentFilters.length > 0 && (
              <>
                <div className="bg-hh-mine-shaft h-[1px] w-full my-7 lg:my-12"></div>

                <div className="mt-7 lg:mt-12">
                  {parentFilters?.map((parentFilter) => {
                    return <Filters key={parentFilter?.databaseId} fields={fields} data={parentFilter} open={open} setOpen={setOpen} />
                  })}
                </div>

                <div className="mt-14">
                  <button type="button" onClick={clear} className="button-link">
                    Clear all
                  </button>
                </div>
              </>
            )}
          </motion.div>
        </div>
        <div className="col-span-9 relative">
          <div className="flex items-center justify-between mt-4 lg:mt-0">
            <div className="title text-xl font-light">{fields.postType?.pageInfo?.total} results</div>

            {fields.enableSorting && (
              <div className="mb-4">
                <Ordering />
              </div>
            )}
          </div>

          {items.length === 0 && <Message>No items found</Message>}

          <div className="grid grid-cols-2 sm:grid-cols-3 gap-5 mt-10">
            {items.map((edge: Edge, index) => {
              const Component = PostTypes[postTypeName] || PostTypes.Post

              if (fields.custom && index > 0 && index % 5 === 0) {
                return (
                  <Fragment key={edge.node.uri}>
                    <CustomItem edge={fields.customItem} />
                    <Component key={edge.node.uri} edge={edge} />
                  </Fragment>
                )
              }

              return <Component key={edge.node.uri} edge={edge} />
            })}
          </div>
        </div>
      </div>

      <div className="mt-10">
        <Pagination />
      </div>
    </>
  )
}

export const PostTypes: { [key: string]: any } = {
  Knowledge: KnowledgeItem,
  Post: PostItem,
  Reference: ReferenceItem,
  Product: ProductItem,
  Location: LocationItem,
  Vacancy: VacancyItem
}

function Filters({
  fields,
  data,
  isSubFilters = false,
  open,
  setOpen
}: {
  fields: Page_Flexcontent_Flex_Overview
  data: Maybe<Category>
  isSubFilters?: boolean
  open?: number[]
  setOpen?: (open: number[]) => void
}) {
  const [showMore, setShowMore] = useState(false)
  const { isFilterActive, toggleFilter } = useLosseBlogBink()

  if (!data?.databaseId) {
    return null
  }

  const id = data.databaseId
  const filters = fields.filters?.filter((filter) => filter?.parentDatabaseId === id)
  const filteredFilters = filters?.filter((_filter, index) => showMore || index < SHOW_MAX_FILTERS)
  const hasMore = filteredFilters && (filters?.length || 0) > filteredFilters?.length

  return (
    <>
      {!isSubFilters && (
        <div
          role="button"
          className="flex items-center justify-between hover:underline pr-1"
          onClick={() => {
            if (!open || typeof setOpen === 'undefined') {
              return
            }

            const currentIndex = open.indexOf(id)

            if (currentIndex !== -1) {
              setOpen([...open.slice(0, currentIndex), ...open.slice(currentIndex + 1)])
            } else {
              setOpen([...open, id])
            }
          }}
        >
          <h2 className="text-hh-mine-shaft title font-bold text-2xl">{data?.name}</h2>
          <motion.div
            animate={open?.includes(id) ? 'active' : 'inactive'}
            variants={{
              inactive: {
                rotate: 0
              },
              active: {
                rotate: 90
              }
            }}
            className="flex items-center justify-center w-4 h-4"
          >
            <svg
              width="6"
              height="11"
              viewBox="0 0 6 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition-colors text-hh-mine-shaft w-full h-full"
            >
              <path
                d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
                fill="currentColor"
              />
            </svg>
          </motion.div>
        </div>
      )}
      {filteredFilters && filteredFilters.length > 0 && (
        <motion.div
          animate={isSubFilters || open?.includes(id) ? 'active' : 'inactive'}
          variants={{
            inactive: {
              height: 0,
              opacity: 0
            },
            active: {
              height: 'auto',
              opacity: 1
            }
          }}
          className="overflow-hidden mb-4"
        >
          <div className={clsx(!isSubFilters && 'pt-3')}>
            {filteredFilters?.map((filter) => (
              <div key={filter?.databaseId} className="pl-4">
                <label className="flex items-center hover:bg-hh-concrete/75 rounded-l-2xl hover:cursor-pointer py-1 px-2">
                  <input
                    type="checkbox"
                    checked={isFilterActive(filter?.databaseId)}
                    onChange={() => {
                      toggleFilter(filter?.databaseId)
                    }}
                    className="appearance-none h-4 w-4 border block border-hh-emperor rounded-full bg-white checked:bg-hh-blue checked:border-hh-blue focus:outline-none transition duration-200"
                  />
                  <span className="text-lg leading-5 pt-[1px] capitalize text-hh-mine-shaft title font-light pl-3 text-ellipsis whitespace-nowrap overflow-hidden w-full">
                    {filter?.name}
                  </span>
                </label>
                <Filters fields={fields} data={filter} isSubFilters />
              </div>
            ))}
          </div>
          {(hasMore || showMore) && (
            <button
              type="button"
              onClick={() => {
                setShowMore(!showMore)
              }}
              className="button-link mt-1 mb-4 pl-4"
            >
              {showMore ? 'Hide ...' : 'Show more ...'}
            </button>
          )}
        </motion.div>
      )}
    </>
  )
}
