import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import clsx from 'clsx'
import Form from '~/components/elements/form/Form'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section className="relative" id="contact">
      <div className="absolute inset-0 z-0 hidden lg:grid grid-cols-2">
        <div
          className={clsx(
            'col-span-1',
            fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
            fields.backgroundColor === 'white' && 'bg-white'
          )}
        />
        {fields.backgroundColor !== 'white' && <div className="col-span-1 bg-hh-concrete" />}
      </div>
      <div className="relative z-10 container max-w-screen-lg section">
        <div
          className={clsx(
            (fields.styletype === 'with_video' || !!fields.description) && 'grid',
            fields.styletype === 'contact' && 'lg:grid-cols-2 lg:gap-16 xl:gap-32',
            fields.styletype === 'with_video' && 'lg:grid-cols-12 gap-16'
          )}
        >
          <div
            className={clsx(
              'flex items-start',
              fields.styletype === 'contact' && 'col-span-1',
              fields.styletype === 'with_video' && 'col-span-7'
            )}
          >
            {fields.styletype === 'contact' && <Content className="content">{fields?.description}</Content>}
            {fields.styletype === 'with_video' && (
              <iframe title="Heinen Hopman" src={fields.videoUrl || ''} className="w-full aspect-video" loading="lazy" />
            )}
          </div>
          <div
            className={clsx(
              'lg:bg-transparent bg-hh-concrete p-5 lg:p-0 mt-5 lg:mt-0 relative flex justify-center',
              fields.styletype === 'contact' && 'col-span-1',
              fields.styletype === 'with_video' && 'col-span-5'
            )}
          >
            <div>
              {fields.styletype === 'with_video' && fields?.description && (
                <Content className="content mb-8">{fields?.description}</Content>
              )}
              <Form className="max-w-xl" data={fields.form} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
