import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Video } from '~/graphql/types'

export default function Video({ fields }: { fields: Page_Flexcontent_Flex_Video }) {
  return (
    <section
      id="Video"
      className={clsx(
        'section-text',
        fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
        fields.backgroundColor === 'mineshaft' && 'bg-hh-mine-shaft',
        fields.backgroundColor === 'white' && 'bg-white',
        fields.backgroundColor === 'pattensblue' && 'bg-hh-blue/20'
      )}
    >
      <div className="container xl:max-w-screen-xl flex justify-center">
        <iframe title="Heinen Hopman" src={fields.url || ''} className="aspect-video w-full max-w-screen-lg" />
      </div>
    </section>
  )
}
