import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Clock from 'react-clock'
import type { LoaderData } from '@ubo/losse-sjedel'
import type { Location, Page_Flexcontent_Flex_Locations } from '~/graphql/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import useMediaQuery from '~/hooks/useMediaQuery'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)

export default function LocationsTimetable({ fields }: { fields: Page_Flexcontent_Flex_Locations }) {
  const data = useLoaderData<LoaderData<Pick<Location, 'recap'>>>()
  let [currentDate, setCurrentDate] = useState(dayjs().utc())
  // const xl = useMediaQuery('(min-width: 1280px)')
  // const lg = useMediaQuery('(min-width: 1024px)')
  const md = useMediaQuery('(min-width: 768px)')

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(dayjs().utc()), 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  // offsets are from the google timezone api, when saving the post in WordPress these get fetched
  if (data.page.recap?.dstOffset !== undefined && data.page.recap.timezoneOffset !== undefined) {
    currentDate = currentDate.add(Number(data.page.recap.dstOffset), 'second')
    currentDate = currentDate.add(Number(data.page.recap.timezoneOffset), 'second')
  }

  let isOpen = null
  if (fields.timetable) {
    const timetable = [
      fields.timetable[6],
      fields.timetable[0],
      fields.timetable[1],
      fields.timetable[2],
      fields.timetable[3],
      fields.timetable[4],
      fields.timetable[5]
    ]

    const currentDay = timetable[currentDate.day()]

    if (currentDay?.closed) {
      isOpen = false
    } else {
      const startTime = dayjs.utc(currentDay?.startTime, 'HH:mm').set('date', currentDate.date())
      const endTime = dayjs.utc(currentDay?.endTime, 'HH:mm').set('date', currentDate.date())

      isOpen = currentDate.isBetween(startTime, endTime)
    }
  }

  let clockSize = 80

  if (md) {
    clockSize = 134
  }

  return (
    <section className="container xl:max-w-screen-xl pb-0 section">
      <div className="grid grid-cols-12 lg:gap-x-12 gap-y-10">
        <div className="lg:col-span-5 sm:col-span-7 col-span-12 bg-hh-concrete px-8 py-12 flex flex-col font-hh-heading lg:text-2xl text-xl">
          <span>{data.page.recap?.address}</span>
          <span>{data.page.recap?.city}</span>
          <span className="mb-8">{data.page.recap?.country}</span>

          <LosseLink className="flex items-center gap-x-4 lg:text-xl text-lg mb-4 group" to={`tel:${data.page.recap?.phone}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
              <g clipPath="url(#clip0_457_4567)">
                <path
                  fill="#00A1FF"
                  d="M14.792 16.939A12.638 12.638 0 018.07 10.2l3.542-3.548L5.09.127 1.788 3.43A6.146 6.146 0 000 7.812C0 15.363 9.638 25 17.188 25a6.136 6.136 0 004.382-1.788l3.303-3.303-6.526-6.526-3.555 3.556zm5.304 4.8a4.062 4.062 0 01-2.908 1.178c-6.493 0-15.104-8.612-15.104-15.105A4.07 4.07 0 013.26 4.904l1.83-1.83 3.58 3.58L5.614 9.71l.255.64a14.972 14.972 0 008.799 8.787l.631.241 3.048-3.049 3.58 3.58-1.831 1.83zM14.584 2.084V0A10.428 10.428 0 0125 10.417h-2.083a8.343 8.343 0 00-8.334-8.334zm0 4.167V4.167a6.256 6.256 0 016.25 6.25H18.75a4.167 4.167 0 00-4.166-4.167z"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_457_4567">
                  <path fill="#00A1FF" d="M0 0H25V25H0z"></path>
                </clipPath>
              </defs>
            </svg>

            <span className="group-hover:underline">{data.page.recap?.phone}</span>
          </LosseLink>

          <LosseLink className="flex items-center gap-x-4 lg:text-xl text-lg group" to={`mailto:${data.page.recap?.email}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
              <g clipPath="url(#clip0_457_4566)">
                <path
                  fill="#00A1FF"
                  d="M21.875 1.042H3.125A3.125 3.125 0 000 4.167v19.792h25V4.167a3.125 3.125 0 00-3.125-3.125zM3.125 3.125h18.75a1.042 1.042 0 011.042 1.042v.695l-8.207 8.207a3.132 3.132 0 01-4.42 0L2.083 4.862v-.695a1.042 1.042 0 011.042-1.042zm-1.042 18.75V7.813l6.734 6.729a5.215 5.215 0 007.366 0l6.734-6.73v14.063H2.083z"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_457_4566">
                  <path fill="#00A1FF" d="M0 0H25V25H0z"></path>
                </clipPath>
              </defs>
            </svg>

            <span className="group-hover:underline">{data.page.recap?.email}</span>
          </LosseLink>
        </div>

        {/* <div className="col-span-1"></div> */}

        <div className="lg:col-span-7 col-span-12 grid grid-cols-3">
          <div className="clock mx-auto bg-hh-emperor text-white p-4 flex flex-col items-center justify-center w-full">
            <Clock
              className="mb-6"
              size={clockSize}
              hourMarksLength={20}
              hourMarksWidth={2}
              value={currentDate.subtract(dayjs().utcOffset(), 'minutes').toDate()}
            />

            <span className="uppercase font-hh-heading sm:text-2xl text-sm mb-4">Local time</span>

            <div className="flex flex-col w-[136px]">
              <span className="px-2 py-2 bg-hh-blue font-hh-heading sm:text-2xl text-base font-medium sm:w-full w-[100px] self-center text-center leading-none">
                {currentDate.format('HH:mm')}
              </span>
              <span className="bg-white text-black text-center sm:w-full w-[100px] self-center font-hh-form text-sm py-1">
                {isOpen ? 'Now open' : 'Closed'}
              </span>
            </div>
          </div>

          <div className="col-span-2 bg-hh-blue/20 sm:py-8 py-4 sm:px-10 px-6 lg:text-lg sm:text-base text-sm font-hh-form flex flex-col justify-between">
            {fields.timetable?.map((item) => (
              <div className="flex items-center justify-between" key={item?.day}>
                <span className="text-left capitalize">{item?.day}</span>
                <span className="text-right capitalize">{item?.closed ? 'closed' : `${item?.startTime} - ${item?.endTime}`}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
