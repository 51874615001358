import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import clsx from 'clsx'
import { classes } from './TextShell'
import { losseContentParse } from '@ubo/losse-sjedel'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section className={classes(fields)}>
      <div className={clsx('container', fields.width === 'twothirdscenter' ? 'lg:max-w-screen-lg' : 'xl:max-w-screen-xl')}>
        <div className="grid lg:grid-cols-3">
          <div className={clsx('content', fields.width === 'twothirds' ? 'col-span-2' : 'col-span-3')}>
            {losseContentParse(fields?.description)}
          </div>
        </div>
      </div>
    </section>
  )
}
