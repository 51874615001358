import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import QuoteDefault from './QuoteDefault'

const PostsShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: QuoteDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default PostsShell
