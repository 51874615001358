import { motion, AnimatePresence } from 'framer-motion'
import { losseContentParse, LosseLink, useLocation } from '@ubo/losse-sjedel'
import useMediaQuery from '~/hooks/useMediaQuery'
import DynamicSubmenu from './DynamicSubmenu'
import type { DynamicMenuEdges } from './HeaderDynamic'

interface DynamicMenuProps {
  items?: DynamicMenuEdges
  filteredItems?: DynamicMenuEdges
  menu: {
    current: number
    submenu: number
  }
  setMenu: (menu: { current: number; submenu: number }) => void
}

export default function DynamicMenu({ items, filteredItems, menu, setMenu }: DynamicMenuProps) {
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const location = useLocation()

  // if location.pathname includes /tr/ then filter out Air Distribution
  if (location.pathname.includes('/tr/')) {
    filteredItems = filteredItems?.filter((item) => item?.node?.title !== 'Air Distribution')
  }

  return (
    <div className="relative flex flex-col gap-5">
      {filteredItems?.map((l, index: number) => (
        <motion.div
          animate={menu.submenu === index && !isMobile ? { paddingLeft: 25 } : { paddingLeft: 0 }}
          className={'relative flex'}
          key={index}
        >
          <AnimatePresence>
            {menu.submenu === index && (
              <motion.svg
                initial={{ opacity: 0, x: -25 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -25 }}
                className="absolute lg:block hidden top-0 bottom-0 my-auto left-0"
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 10C12.0007 10.3915 11.9161 10.7794 11.7509 11.1412C11.5857 11.503 11.3432 11.8316 11.0374 12.1082L2.32661 20L-1.07969e-07 17.8918L8.70917 10L-9.16285e-07 2.10825L2.32661 -8.68032e-08L11.0358 7.89176C11.3419 8.16825 11.5847 8.49685 11.7501 8.85866C11.9156 9.22047 12.0005 9.60835 12 10Z"
                  fill="#00A1FF"
                />
              </motion.svg>
            )}
          </AnimatePresence>
          <motion.div
            className="w-full"
            onTap={() => setMenu({ ...menu, submenu: index })}
            onHoverStart={() => setMenu({ ...menu, submenu: index })}
          >
            <div className="flex justify-between items-center">
              <LosseLink
                className={`text-[22px] w-full pr-4 lg:text-xl uppercase lg:normal-case leading-[30px] tracking-tight font-hh-heading lg:font-hh-form font-bold lg:font-normal transition-colors duration-200 ${
                  menu.submenu === index ? `text-hh-blue` : `text-white`
                }`}
                to={l?.node?.uri || '/'}
              >
                {losseContentParse(l?.node?.title)}
              </LosseLink>
              {(l?.node.children?.edges?.length || 0) > 0 && (
                <motion.div className="px-4 py-2 relative -right-4" onTap={() => setMenu({ ...menu, submenu: index })}>
                  <svg
                    className={`lg:hidden block transition-all duration-300 ${menu.submenu === index ? `rotate-90` : ``}`}
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 5.66658C6.00037 5.88846 5.95805 6.10823 5.87545 6.31325C5.79286 6.51827 5.67162 6.70451 5.51871 6.86126L1.16331 11.3333L-1.80291e-06 10.1386L4.35458 5.66658L-5.44162e-07 1.19459L1.16331 -8.24388e-05L5.51789 4.47191C5.67095 4.62859 5.79233 4.8148 5.87507 5.01982C5.95781 5.22485 6.00027 5.44465 6 5.66658Z"
                      fill={menu.submenu === index ? `#00A1FF` : `#ffffff`}
                    />
                  </svg>
                </motion.div>
              )}
            </div>

            <AnimatePresence>
              {menu.submenu === index && (
                <motion.div
                  key={`dynamic-mobile-submenu-${index}`}
                  className="overflow-hidden lg:!hidden block"
                  initial={{ height: 0 }}
                  animate={{ height: 'fit-content' }}
                  transition={{ duration: 0.2 }}
                  exit={{ height: 0 }}
                >
                  <DynamicSubmenu menu={menu} items={items} filteredItems={filteredItems} />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      ))}
    </div>
  )
}
