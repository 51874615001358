import React from 'react'

// Components
import LocationsSearchable from './LocationsSearchable'
import LocationsRegion from './LocationsRegion'
import LocationsTimetable from './LocationsTimetable'
import ServiceLocations from './ServiceLocations'

export interface LocationsProps {
  fields: any
  index: number
}

interface Props {
  [key: string]: React.FC<LocationsProps>
}

const LocationsShell: React.FC<LocationsProps> = ({ fields, index }) => {
  const types: Props = {
    searchable: LocationsSearchable,
    region: LocationsRegion,
    timetable: LocationsTimetable,
    service: ServiceLocations
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return <Component key={index} index={index} fields={fields} />
}

export default LocationsShell
