/* eslint-disable react/no-array-index-key */
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'

interface MegaSubmenuProps {
  currentSubmenu: number
  fields: Maybe<Component_Header_MenuHeader_Submenu>[]
}

const MegaSubmenu: React.FC<MegaSubmenuProps> = function ({ currentSubmenu, fields }) {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        id={`submenu-${currentSubmenu}`}
        key={`submenu-${currentSubmenu}`}
        className="relative flex flex-col gap-8 lg:gap-14"
        initial={{ y: '-10%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '10%', opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {fields.map((f, index: number) => (
          <LosseLink
            key={index}
            className="font-hh-heading hover:underline font-bold text-[22px] text-white lg:text-hh-mine-shaft lg:text-3xl uppercase"
            to={f?.link?.url || '/'}
          >
            {f?.link?.title}
          </LosseLink>
        ))}
      </motion.div>
    </AnimatePresence>
  )
}

export default MegaSubmenu
