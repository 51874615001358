import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import AccordionDefault from './AccordionDefault'

const AccordionShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: AccordionDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default AccordionShell
