import { LosseLink } from '@ubo/losse-sjedel'
import type { Maybe } from '~/graphql/types'
import { type Page_Flexcontent_Flex_Overview_CustomItem } from '~/graphql/types'

export default function CustomItem({ edge }: { edge: Maybe<Page_Flexcontent_Flex_Overview_CustomItem> | undefined }) {
  return (
    <div className="col-span-1 w-full bg-hh-blue flex flex-col justify-between p-3 sm:py-10 sm:px-7 relative">
      <h2 className="title text-white uppercase text-xl sm:text-2xl xl:text-4xl">{edge?.title}</h2>

      <svg
        width="218"
        height="337"
        viewBox="0 0 218 337"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
      >
        <path
          opacity="0.1"
          d="M122.28 275.909H66.6529V337H122.28V275.909ZM123.283 229.841C123.283 197.793 124.786 195.79 159.366 181.269C187.931 169.251 191.94 167.248 200.961 156.232C213.49 141.21 218 124.685 218 91.1352C218 54.0802 211.986 34.5513 196.451 20.5305C179.411 5.50816 157.361 0 114.262 0C65.1494 0 36.5839 6.50966 20.046 22.0327C5.51264 35.5527 1.0023 51.0758 0.501149 91.636L0 104.155H55.6276C55.6276 81.6211 55.6276 79.1174 56.6299 73.6092C60.6391 52.578 73.1678 46.5691 111.255 46.5691C154.855 46.5691 162.874 54.0802 162.874 93.6389C162.874 110.664 159.867 121.18 153.352 127.189C148.34 132.697 146.837 133.198 115.264 146.718C72.6667 164.744 66.1517 175.761 66.6529 229.841H123.283Z"
          fill="white"
        />
      </svg>

      <LosseLink src={edge?.link} className="button-white-opaque lg:min-w-[175px] whitespace-nowrap min-w-0 w-full" />
    </div>
  )
}
