import { LosseLink, type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderSearch from './HeaderSearch'
import HeaderMega from './Mega/HeaderMega'
import HeaderDynamic from './Dynamic/HeaderDynamic'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <div className={clsx('fixed left-0 w-full h-auto z-50')}>
      <nav className={clsx('transition-all z-10 bg-black h-[75px]', !isSticky && 'lg:h-[100px]')}>
        <div className="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-3 container h-full items-center justify-between">
          <LosseLink className="col-span-1" aria-label="Home" to="/">
            <svg
              className="w-[125px] lg:w-[158px]"
              width="158"
              height="55"
              viewBox="0 0 158 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_368_1344)">
                <path
                  d="M102.803 15.9854L101.806 17.3562L99.6185 13.7289H97.7339L95.7195 10.0805H92.0804L90.1958 7.5498H85.1054L83.2426 10.0805H80.9465V28.5122H103.301L115.475 16.0065L102.803 15.9854ZM81.5963 10.7131H83.5891L85.452 8.18247H89.8709L91.7554 10.7131H95.3295L97.344 14.3615H99.2286L101.373 17.9045L100.615 18.9589H81.5746V10.7131H81.5963ZM103.019 27.8796H81.5963V19.6127H100.961L103.128 16.6181H113.937L103.019 27.8796Z"
                  fill="white"
                />
                <path
                  d="M52.4836 10.0806L41.7396 20.8359H46.1152V28.4912H76.2677V10.0806H52.4836ZM75.5962 27.8796H46.765V20.2244H43.2776L52.6136 10.8819L61.1481 19.6339H75.6178V27.8796H75.5962ZM75.5962 18.9801H61.408L53.3717 10.7132H75.5962V18.9801Z"
                  fill="white"
                />
                <path
                  d="M61.2348 0V5.84164H56.1877V0H40.0067V37.1166H56.1877V31.2749H61.2348V37.1166H77.3291V0H61.2348ZM75.5962 35.4295H62.9677V29.5878H54.4548V35.4295H41.7396V1.68712H54.4548V7.52876H62.9677V1.68712H75.5962V35.4295Z"
                  fill="white"
                />
                <path d="M75.5962 10.7134V18.9803H61.408L53.3717 10.7134H75.5962Z" fill="#10944B" />
                <path d="M75.5962 19.6128V27.8796H46.765V20.2032H43.2776L52.6136 10.8608L61.1481 19.6128H75.5962Z" fill="#10944B" />
                <path
                  d="M101.091 0V5.84164H96.0444V0H79.8634V37.1166H96.0444V31.2749H101.091V37.1166H117.186V0H101.091ZM115.453 35.4295H102.824V29.5878H94.3115V35.4295H81.5963V1.68712H94.3115V7.52876H102.824V1.68712H115.453V35.4295Z"
                  fill="white"
                />
                <path d="M113.937 16.6182L103.019 27.8797H81.5963V19.6128H100.961L103.128 16.6182H113.937Z" fill="#1296D3" />
                <path
                  d="M101.395 17.9257L100.637 18.9802H81.5963V10.7133H83.5891L85.452 8.18262H89.8709L91.7554 10.7133H95.3295L97.3657 14.3828H99.2502L101.395 17.9257Z"
                  fill="#1296D3"
                />
                <path
                  d="M0.041687 47.1128H2.48941V49.8965H7.66645V47.1128H10.1142V54.8946H7.66645V51.9422H2.48941V54.8946H0.041687V47.1128Z"
                  fill="white"
                />
                <path
                  d="M11.7821 47.1128H20.5333V48.9264H14.2298V50.1707H20.1867V51.7735H14.2298V53.0177H20.6632V54.8946H11.7821V47.1128Z"
                  fill="white"
                />
                <path d="M22.2878 47.1128H24.7355V54.8946H22.2878V47.1128Z" fill="white" />
                <path
                  d="M26.5117 47.1128H30.3024L34.9163 52.7646H34.9379V47.1128H37.3857V54.8946H33.5733L28.9594 49.2428H28.9378V54.8946H26.4901V47.1128H26.5117Z"
                  fill="white"
                />
                <path
                  d="M39.0536 47.1128H47.8047V48.9264H41.5013V50.1707H47.4798V51.7735H41.5013V53.0177H47.9347V54.8946H39.0536V47.1128Z"
                  fill="white"
                />
                <path
                  d="M49.4294 47.1128H53.2201L57.8339 52.7646H57.8556V47.1128H60.3033V54.8946H56.5126L51.8988 49.2428H51.8771V54.8946H49.4294V47.1128Z"
                  fill="white"
                />
                <path
                  d="M67.3432 52.1741C67.3649 50.951 67.7114 50.5081 69.0761 50.4659V50.4448C68.2963 49.9809 68.253 49.7278 68.253 48.7999C68.253 47.3026 68.6429 47.0073 71.1339 47.0073H73.0401C75.8344 47.0073 76.2893 47.3447 76.2893 49.2006V49.6224H74.1665V49.2428C74.1665 48.821 73.8849 48.6101 73.0834 48.6101H71.3938C70.7007 48.6101 70.5274 48.6944 70.5274 49.074C70.5274 49.3693 70.5707 49.4747 71.0473 49.7278L75.0329 51.7312V50.6979H77.0258V51.7945C77.0302 52.0989 77.0011 52.4029 76.9391 52.7013L78.3688 53.4184L77.6973 54.8735L76.2893 54.1565C75.8127 54.8946 74.6647 54.9579 73.625 54.9579H70.3108C68.5129 54.9579 67.3432 54.515 67.3432 52.7435V52.1741ZM74.2531 53.3129C74.2619 53.3139 74.2708 53.313 74.2792 53.3102C74.2875 53.3074 74.2951 53.3027 74.3014 53.2966C74.3077 53.2905 74.3124 53.2832 74.3153 53.275C74.3182 53.2669 74.3192 53.2582 74.3181 53.2497L70.5491 51.3727C70.4314 51.3296 70.3065 51.3081 70.1808 51.3095C69.8342 51.3095 69.531 51.4149 69.531 52.2796C69.531 53.102 69.9209 53.2918 70.744 53.2918L74.2531 53.3129Z"
                  fill="white"
                />
                <path
                  d="M84.6072 47.1128H87.055V49.8965H92.232V47.1128H94.6797V54.8946H92.232V51.9422H87.055V54.8946H84.6072V47.1128Z"
                  fill="white"
                />
                <path
                  d="M96.1744 49.8542C96.1744 47.5766 97.4957 47.0283 99.7052 47.0283H103.626C105.835 47.0283 107.157 47.5555 107.157 49.8542V52.174C107.157 54.4516 105.835 55 103.626 55H99.6835C97.474 55 96.1527 54.4727 96.1527 52.174L96.1744 49.8542ZM98.6871 51.4359C98.6871 52.5747 98.9254 52.9965 100.095 52.9965H103.236C104.406 52.9965 104.644 52.5747 104.644 51.4359V50.5924C104.644 49.6223 104.406 49.0318 103.106 49.0318H100.225C98.9254 49.0318 98.6871 49.6223 98.6871 50.5924V51.4359Z"
                  fill="white"
                />
                <path
                  d="M108.63 47.1128H115.323C117.662 47.1128 118.226 48.2516 118.226 49.77V50.4449C118.226 51.6047 117.706 52.9334 115.973 52.9334H111.099V54.8946H108.651V47.1128H108.63ZM111.077 51.0143H114.89C115.475 51.0143 115.691 50.6557 115.691 50.1918V49.8965C115.691 49.3693 115.431 49.0741 114.695 49.0741H111.099V51.0143H111.077Z"
                  fill="white"
                />
                <path
                  d="M119.525 47.1128H123.294L126.067 52.132L128.84 47.1128H132.609V54.8946H130.226V49.306H130.204L126.977 54.8946H125.135L121.93 49.306H121.908V54.8946H119.525V47.1128Z"
                  fill="white"
                />
                <path
                  d="M137.807 47.1128H140.991L145.389 54.8946H142.659L141.901 53.5238H136.941L136.226 54.8946H133.475L137.807 47.1128ZM137.851 51.8367H140.991L139.41 48.9054L137.851 51.8367Z"
                  fill="white"
                />
                <path
                  d="M146.277 47.1128H150.068L154.681 52.7646H154.703V47.1128H157.151V54.8946H153.36L148.746 49.2428H148.725V54.8946H146.277V47.1128Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_368_1344">
                  <rect width="157.109" height="55" fill="white" transform="translate(0.041687)" />
                </clipPath>
              </defs>
            </svg>
          </LosseLink>

          <div className="col-span-1 lg:block hidden">
            <HeaderSearch />
          </div>

          <HeaderMega className="col-span-2 2xl:col-span-1" />
        </div>
      </nav>
      <div
        className={clsx(
          'bg-hh-mine-shaft z-10 w-screen hidden lg:flex gap-36 items-center justify-center',
          !isSticky && 'h-[75px]',
          isSticky && 'h-[50px] shadow-md'
        )}
      >
        <HeaderDynamic />
      </div>
    </div>
  )
}
