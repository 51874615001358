import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import { type Page_Flexcontent_Flex_Text } from '~/graphql/types'

// Components
import TextDefault from './TextDefault'
import TextImage from './TextImage'
import TextVideo from './TextVideo'
import TextBreadcrumbs from './TextBreadcrumbs'
import TextSplit from './TextSplit'
import clsx from 'clsx'

const TextShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    default: TextDefault,
    image: TextImage,
    video: TextVideo,
    breadcrumbs: TextBreadcrumbs,
    split: TextSplit
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export function classes(fields: Page_Flexcontent_Flex_Text) {
  return clsx(
    (fields.spacing === 'topandbottom' || !fields.spacing) && 'section-text',
    fields.spacing === 'top' && 'section-text-top',
    fields.spacing === 'bottom' && 'section-text-bottom',
    fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
    fields.backgroundColor === 'mineshaft' && 'bg-hh-mine-shaft text-white',
    fields.backgroundColor === 'white' && 'bg-white',
    fields.backgroundColor === 'pattensblue' && 'bg-hh-blue/20'
  )
}

export default TextShell
