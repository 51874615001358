import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Accordion } from '~/graphql/types'
import { motion } from 'framer-motion'
import { useState } from 'react'
import clsx from 'clsx'

export default function AccordionDefault({ fields }: { fields: Page_Flexcontent_Flex_Accordion }) {
  const [active, setActive] = useState(0)

  const activeItem = fields?.items?.find((item, index) => index === active)

  return (
    <section>
      <div className="container">
        <div
          className={clsx(
            fields.backgroundColor === 'white' && 'bg-white',
            fields.backgroundColor === 'concrete' && 'bg-hh-concrete',
            'section'
          )}
        >
          {fields.description && (
            <Content className="content-lg px-10 children-h2:text-hh-mine-shaft children-h2:text-opacity-10 mb-7 lg:mb-16">
              {fields.description}
            </Content>
          )}

          <div className="xl:max-w-screen-lg mx-auto px-6">
            <div className="md:grid grid-cols-2 gap-12">
              <div className="col-span-1 flex justify-center items-center">
                {activeItem && (
                  <img
                    className="object-contain max-w-xs sm:max-w-sm md:max-w-full max-h-full"
                    src={activeItem.image?.mediaItemUrl || ''}
                    alt={activeItem.title || ''}
                  />
                )}
              </div>
              <div className="col-span-1">
                {fields.items?.map((edge, index) => (
                  <div key={index}>
                    <div
                      role="button"
                      className="flex items-center justify-between py-3 hover:underline"
                      onClick={() => {
                        if (index === active) {
                          setActive(-1)
                        } else {
                          setActive(index)
                        }
                      }}
                    >
                      <h2
                        className={clsx(
                          'title text-lg md:text-xl lg:text-2xl transition-colors',
                          index === active ? 'text-hh-blue' : 'text-hh-mine-shaft'
                        )}
                      >
                        {edge?.title}
                      </h2>
                      <motion.div
                        animate={index === active ? 'active' : 'inactive'}
                        variants={{
                          inactive: {
                            rotate: 0
                          },
                          active: {
                            rotate: 90
                          }
                        }}
                        className="flex items-center justify-center"
                      >
                        <svg
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={clsx('w-4 h-4', index === active ? 'text-hh-blue' : 'text-hh-mine-shaft')}
                        >
                          <path
                            d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </motion.div>
                    </div>
                    <motion.div
                      animate={index === active ? 'active' : 'inactive'}
                      variants={{
                        inactive: {
                          height: 0,
                          opacity: 0
                        },
                        active: {
                          height: 'auto',
                          opacity: 1
                        }
                      }}
                      className="overflow-hidden h-0"
                    >
                      <Content className="content text-hh-mine-shaft">{edge?.description}</Content>
                    </motion.div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
