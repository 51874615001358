import type { Maybe, Page_Flexcontent_Flex_Overview, WpPageInfo } from '~/graphql/types'
import { LosseBlogBink } from '@ubo/losse-sjedel'
import Message from '~/components/elements/Message'
import { type Edge, getPostTypeName, PostTypes } from './OverviewDefault'

export default function OverviewSimple({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  if (!fields.postType?.pageInfo) {
    return null
  }

  return (
    <>
      <section id="overview" className="bg-hh-concrete">
        <LosseBlogBink
          pageInfo={fields.postType?.pageInfo as Maybe<WpPageInfo> | undefined}
          className="container section xl:max-w-screen-xl"
        >
          <Overview fields={fields} />
        </LosseBlogBink>
      </section>
    </>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  const items = fields.postType?.edges as Edge[]
  const postTypeName = getPostTypeName(fields)

  return (
    <>
      {items.length === 0 && <Message>No items found</Message>}

      <div className="grid grid-cols-2 gap-5">
        {items.map((edge: Edge, index) => {
          const Component = PostTypes[postTypeName] || PostTypes.Post

          return <Component key={edge.node.uri} edge={edge} fields={fields} />
        })}
      </div>
    </>
  )
}
