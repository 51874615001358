import { LossePlaatjie, LosseLink } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import type { Post } from '~/graphql/types'
import type { Edge } from '../OverviewDefault'

export default function ReferenceItem({ edge }: { edge: Edge<Post> }) {
  return (
    <LosseLink className="col-span-1 relative w-full flex items-end group justify-start h-[300px] overflow-hidden" to={edge.node.uri}>
      <div className="absolute inset-0 z-0">
        <LossePlaatjie
          src={edge.node?.recap?.image}
          maxwidth={650}
          className="w-full h-full object-cover group-hover:scale-105 transition-all"
        />
      </div>
      <motion.div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black/50" />
      <div className="relative z-20 px-5 py-8 text-white w-full">
        <div className="flex items-center mb-3">
          {edge.node.categories?.nodes?.map((category) => (
            <div key={category?.databaseId} className="tag">
              {category?.name}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mt-1 text-white w-full">
          <h2 className="title text-2xl group-hover:underline">{edge.node?.title}</h2>
          <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-3 w-3">
            <path
              d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </LosseLink>
  )
}
