import { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import type { Maybe } from '~/graphql/types'
import useMediaQuery from '~/hooks/useMediaQuery'
import Message from '../Message'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function Reader({ file = '' }: { file: Maybe<string> | undefined }) {
  const [pages, setPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const isMobile = useMediaQuery('(max-width: 641px)')

  useEffect(() => {
    // rerender pls
  }, [isMobile])

  return (
    <div>
      <div className="h-[400px] sm:h-[650px] relative">
        <Document
          key={isMobile ? 'mobile' : 'desktop'}
          className="h-full"
          file={file}
          onLoadSuccess={({ numPages }) => setPages(numPages)}
          loading={<Message className="text-white p-5 text-center">Loading PDF ...</Message>}
          noData={<Message className="text-white p-5 text-center">No PDF found</Message>}
        >
          <Page height={isMobile ? 400 : 650} className="flex justify-center" pageNumber={pageNumber} />
        </Document>
      </div>
      <div className="font-hh-paragraph mt-10 flex items-center justify-center text-white font-light lg:text-m text-sm">
        <button
          type="button"
          onClick={() => {
            if (pageNumber > 1) {
              setPageNumber(pageNumber - 1)
            }
          }}
          className="button-white-opaque min-w-max"
        >
          <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
            <path
              d="M1.66694e-06 5.5C-0.000374548 5.28465 0.041951 5.07135 0.124546 4.87236C0.207141 4.67336 0.328377 4.4926 0.481285 4.34046L4.83669 -5.08498e-08L6 1.15953L1.64541 5.5L6 9.84046L4.83669 11L0.482108 6.65953C0.329051 6.50746 0.207664 6.32673 0.124926 6.12774C0.0421889 5.92874 -0.000266325 5.71541 1.66694e-06 5.5Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <div className="mx-4">
          Page {pageNumber} of {pages}
        </div>
        <button
          type="button"
          onClick={() => {
            if (pageNumber < pages) {
              setPageNumber(pageNumber + 1)
            }
          }}
          className="button-white-opaque min-w-max"
        >
          <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
            <path
              d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}
