import { LosseLink } from '@ubo/losse-sjedel'
import type { Location } from '~/graphql/types'
import type { Edge } from '../OverviewDefault'

export default function LocationItem({ edge }: { edge: Edge<Location> }) {
  return (
    <div className="col-span-1 bg-hh-concrete">
      <div className="relative z-20 p-3 sm:p-5 lg:p-7 text-hh-mine-shaft">
        <LosseLink to={edge.node.uri} className="group">
          <h3 className="title font-light text-sm uppercase">{edge.node?.recap?.country}</h3>
          <span className="text-s">{edge.node?.recap?.city}</span>
          <h2 className="title text-xl group-hover:underline">{edge.node?.title}</h2>
        </LosseLink>
        <div className="paragraph mt-2">
          <a href={`mailto:${edge.node.recap?.email}`} className="button-link block text-ellipsis overflow-hidden whitespace-nowrap">
            <span className="w-4 inline-block font-bold text-hh-blue">E</span>
            {edge.node.recap?.email}
          </a>
          {edge.node.recap?.phone && (
            <a href={`tel:${edge.node.recap?.phone}`} className="button-link block text-ellipsis overflow-hidden whitespace-nowrap mt-1">
              <span className="w-4 inline-block font-bold text-hh-blue">T</span>
              {edge.node.recap?.phone}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
