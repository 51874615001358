import type { GfForm, Knowledge } from '~/graphql/types'
import { LossePlaatjie, useLoaderData, useLosseData, useSearchParams } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Modal from '~/components/elements/Modal'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/form/Form'

export default function KnowledgeModal() {
  const [searchParams, setSearchParams] = useSearchParams()
  const featured = searchParams.get('download-knowledge-featured')
  const knowledgeItemId = searchParams.get('download-knowledge') || featured
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (!knowledgeItemId) {
      return
    }

    setSelected(knowledgeItemId)
  }, [knowledgeItemId])

  useEffect(() => {
    if (!selected && knowledgeItemId) {
      setSearchParams({})

      window.location.hash = featured ? 'featured' : 'overview'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (!knowledgeItemId) return

    setTimeout(() => {
      window.location.hash = featured ? 'featured' : 'overview'
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeItemId])

  return <ModalContent key={selected} selected={selected} setSelected={setSelected} />
}

function ModalContent({ selected, setSelected }: { selected: string; setSelected: (value: string) => void }) {
  const [response, state] = useLosseData<{ data: Knowledge; form: GfForm }>(
    'KnowledgeForm',
    {
      id: Number(selected)
    },
    !!selected
  )
  const { request } = useLoaderData()
  const { origin } = new URL(request.url)
  const { data, form } = response || {}

  return (
    <Modal
      open={!!selected}
      onClose={() => {
        setSelected('')
      }}
    >
      {state === 'loading' && (
        <div className="flex justify-center py-20">
          <Loading />
        </div>
      )}
      {state === 'done' && (
        <div className="sm:grid grid-cols-12 gap-10 lg:gap-20 sm:py-10">
          <div className="col-span-6 lg:col-span-7">
            <div className="lg:grid grid-cols-12 gap-10">
              <div className="col-span-5">
                <LossePlaatjie
                  key={data?.recap?.image?.mediaItemUrl}
                  maxwidth={200}
                  src={data?.recap?.image}
                  className="max-w-[80px] object-contain sm:max-w-[125px] block mx-auto knowledge-image"
                />
              </div>
              <div className="col-span-7 mt-4 lg:mt-0">
                <h2 className="title text-xl sm:text-2xl xl:text-4xl uppercase">{data?.title}</h2>
              </div>
            </div>

            <Content className="content-lg mt-6 lg:mt-14">{data?.recap?.description}</Content>
          </div>
          <div className="col-span-6 lg:col-span-5 mt-6 sm:mt-0">
            <Form
              data={form}
              backgroundColor="white"
              defaultFields={{
                input_13: `${origin.replace(':80', '')}${data?.recap?.pdf?.mediaItemUrl}`,
                input_15: `${origin.replace(':80', '')}${data?.recap?.image?.mediaItemUrl}`,
                input_16: `${data?.recap?.description?.replace(/(<([^>]+)>)/gi, '')}`
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
